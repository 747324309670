import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Row, Col, Container, Card, CardBody, CardTitle, Label, Button, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import Select from 'react-select';
import Filter from '../../../../shared/components/filter/TimePeriodFilter';
import Errors from '../ErrorsV2';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import { clear } from '../../../../shared/helpers/LocalStorageCache';
import Actions from './Actions';
import GenerateByPublishTable from './GenerateByPublishTable';
import GenerateCreatorsTable from './GenerateCreatorsTable';
import { formatCurrency, formatIntNumber } from '../../../../shared/helpers/WVFormatter';
import { calculateDays, getDefaultTargetCac, getProjectedPercent } from '../../../../shared/helpers/CACHelper';
import ChartComponent from './ChartComponent';
import LoadingReport from '../../../../shared/components/LoadingReport';
import AccessComponent from '../../../../shared/components/auth/AccessComponent';

const dealTypes = {
  hybrid: 'Hybrid',
  cpa: 'CPA/Ambassador',
  upfront: 'Upfront Only',
  upfrontCPM: 'Upfront CPM',
  agentCommission: 'Agent Commission',
  mediaLicense: 'Media License',
  bonus: 'Bonus',
};

const dataOptions = [
  { value: 'ActiveCreators', label: 'Active Creators' },
  { value: 'CreatorsPublished', label: 'Creators Published' },
  { value: 'ConversionsInTimeframe', label: 'Revenue Conversions In Timeframe' },
  { value: 'ConversionsToDate', label: 'Revenue Conversions To Date' },
  { value: 'Spend', label: 'Spend' },
];

const dateOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
];

const showWhenTeamIs = ['management', 'super_powers', 'engineering'];

const TestToWinReport = ({ addNotice }) => {
  const [activeTab, setActiveTab] = useState('byPublish');
  const [loading, setLoading] = useState(false);
  const [showPredicted, setShowPredicted] = useState(false);
  const [byPublishItems, setByPublishItems] = useState([]);
  const [byCreatorItems, setByCreatorItems] = useState([]);
  const [timeframeSummary, setTimeframeSummary] = useState([]);
  const [winnersSummary, setWinnersSummary] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().endOf('day')).format('YYYY-MM-DD'));
  const [startDateET, setStartDateET] = useState(moment.utc(moment.tz(moment().startOf('month'), 'America/New_York')));
  const [endDateET, setEndDateET] = useState(() =>{
    const yesterdayEndOfDay = moment().endOf('day');
    const startOfCurrentMonth = moment().startOf('month');
    if (yesterdayEndOfDay.isBefore(startOfCurrentMonth)) {
      return moment.utc(moment.tz('America/New_York').startOf('month'));
    }
    return moment.utc(moment.tz('America/New_York').endOf('day'));
  });
  const [errors, setErrors] = useState([]);
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [fetched, setFetched] = useState(false);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedDealType, setSelectedDealType] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState([]);
  const [selectedDataOption, setSelectedDataOption] = useState(dataOptions[0]);
  const [selectedDateOption, setSelectedDateOption] = useState(dateOptions[0]);
  const [statsDataByDates, setStatsDataByDates] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const toDateET = moment.utc(moment.tz('America/New_York').endOf('day'));

  async function fetchMinDate() {
    const resp = await axios.get('/api/portal/reports/get-media-content-min-date');
    setMinDate(resp.data.minDate);
  }

  useEffect(() => {
    fetchMinDate();
  }, []);

  useEffect(() => {
    setStartDateET(moment.utc(moment.tz(startDate, 'America/New_York').startOf('day')));
    setEndDateET(moment.utc(moment.tz(endDate, 'America/New_York').endOf('day')));
  }, [startDate, endDate]);

  const getMediaContentPublishDate = (mediaContent) => {
    if (mediaContent?.publishDate) {
      return moment.tz(mediaContent.publishDate, 'America/New_York');
    } else if (mediaContent?.urlStatusLastModified) {
      return moment.utc(mediaContent.urlStatusLastModified).tz('America/New_York');
    }
    return false;
  }

  const compareMomentDatesAsc = (a, b) => a.valueOf() - b.valueOf();

  const compareMediaContentsPublishDatesAsc = (m1, m2) => {
    if (m1.publishDate && m2.publishDate) {
      return compareMomentDatesAsc(m1.publishDate, m2.publishDate)
    }
    return 0;
  };

  const compareDealDataFirstPublishDatesAsc = (m1, m2) => {
    if (m1.firstPublishDate && m2.firstPublishDate) {
      return compareMomentDatesAsc(m1.firstPublishDate, m2.firstPublishDate)
    }
    return 0;
  };
  const isMomentDateBeforeSelectedTimeframe = (date) => {
    return date.isBefore(startDateET);
  };
  const isMomentDateAfterSelectedTimeframe = (date) => {
    return date.isAfter(endDateET);
  };
  const isMomentDateInSelectedTimeframe = (date) => {
    return date.isBetween(startDateET, endDateET, undefined, '[]');
  };

  const getMediaContentFirstPublishDate = (dealData) => {
    const firstMediaContentInDeal = dealData.mediaContentBeforeStartDate?.[0] || dealData.mediaContentInTimeframe?.[0] || dealData.mediaContentToDate?.[0] || false;
    if (firstMediaContentInDeal) {
      return getMediaContentPublishDate(firstMediaContentInDeal);
    }
    return false;
  };

  const getMediaContentLastPublishDate = (dealData) => {
    if (dealData.mediaContentToDate?.length > 0) {
      return getMediaContentPublishDate(dealData.mediaContentToDate[dealData.mediaContentToDate.length - 1]);
    }
    if (dealData.mediaContentBeforeStartDate?.length > 0) {
      return getMediaContentPublishDate(dealData.mediaContentToDate[dealData.mediaContentBeforeStartDate.length - 1]);
    }
    return false;
  };

  const spreadCPADealsMediaContent = (data) => {
    data.forEach((item, index, cur) => {
      // check if we need to spread CPA media contents
      if (item.typeOfDeal === dealTypes.cpa && item.mediaContentInTimeframe?.length > 1) {
        item.mediaContentInTimeframe.forEach((media, mediaIndex) => {
          const publishDate = getMediaContentPublishDate(media);
          let nextPublishDate = false;
          if (publishDate) {
            const nextMediaContent = item.mediaContentToDate?.find(mc => {
              const mcPublishDate = getMediaContentPublishDate(mc);
              return (mcPublishDate && mcPublishDate > publishDate);
            });
            if (nextMediaContent) {
              nextPublishDate = getMediaContentPublishDate(nextMediaContent);
            }
          }
          const itemCopy = {
            ...item,
            mediaContentInTimeframe: [media],
            publishDate,
            nextPublishDate,
          };
          cur.splice(index + mediaIndex + 1, 0, itemCopy);
        });
        cur.splice(index, 1);
      }
    });
    return data;
  }

  const getCreatorBrandKeyFromDealData = (data) => {
    if (data?.deal?.ilDealData?.influencer && data?.offer?.advertiser?._id) {
      return `${data.deal.ilDealData.influencer}-${data.offer.advertiser._id}`;
    }
    return false;
  }

  const getCreatorOfferKeyFromDealData = (data) => {
    if (data?.deal?.ilDealData?.influencer && data?.deal?.ilDealData?.offer) {
      return `${data.deal.ilDealData.influencer}-${data.deal.ilDealData.offer}`;
    }
    return false;
  }

  const getTypeOfDealFromDealData = (data) => data?.deal?.dealTerms?.type_of_deal || false;
  const getBrandNameFromDealData = (data) => data?.offer?.advertiser?.companyName || false;

  const baseProcessDealsData = (data) => {
    data.forEach((dealData) => {
      if (dealData.mediaContents?.length > 0) {
        const currentMediaContentBeforeStartDate = [];
        const currentMediaContentInTimeframe = [];
        const currentMediaContentToDate = [];
        dealData.mediaContents.forEach((mediaContent) => {
          mediaContent.publishDate = getMediaContentPublishDate(mediaContent);
          if (mediaContent.publishDate) {
            if (isMomentDateBeforeSelectedTimeframe(mediaContent.publishDate)) {
              currentMediaContentBeforeStartDate.push(mediaContent);
            } else if (isMomentDateAfterSelectedTimeframe(mediaContent.publishDate)) {
              currentMediaContentToDate.push(mediaContent);
            } else {
              currentMediaContentToDate.push(mediaContent);
              currentMediaContentInTimeframe.push(mediaContent);
            }
          }
        });
        currentMediaContentBeforeStartDate.sort(compareMediaContentsPublishDatesAsc);
        currentMediaContentInTimeframe.sort(compareMediaContentsPublishDatesAsc);
        currentMediaContentToDate.sort(compareMediaContentsPublishDatesAsc);

        dealData.mediaContentBeforeStartDate = currentMediaContentBeforeStartDate;
        dealData.mediaContentInTimeframe = currentMediaContentInTimeframe;
        dealData.mediaContentToDate = currentMediaContentToDate;

        dealData.hasPublishedInTimeframe = currentMediaContentInTimeframe?.length > 0 || false;
        dealData.firstPublishDateInTimeframe = currentMediaContentInTimeframe?.length > 0 ? getMediaContentPublishDate(currentMediaContentInTimeframe[0]) : false;
        dealData.lastPublishDateInTimeframe = currentMediaContentInTimeframe?.length > 0 ? getMediaContentPublishDate(currentMediaContentInTimeframe[currentMediaContentInTimeframe.length - 1]) : false;
        dealData.firstPublishDate = getMediaContentFirstPublishDate(dealData);
        dealData.lastPublishDate = getMediaContentLastPublishDate(dealData);

        dealData.creatorBrandKey = getCreatorBrandKeyFromDealData(dealData);
        dealData.creatorOfferKey = getCreatorOfferKeyFromDealData(dealData);
        dealData.typeOfDeal = getTypeOfDealFromDealData(dealData);
        dealData.brand = getBrandNameFromDealData(dealData);
      }
    });
    return data.sort(compareDealDataFirstPublishDatesAsc);
  }

  const processDealsDataForNeighboringPublishDates = (data) => {
    if (data?.length > 0) {
      data.forEach((dealData) => {
        if (dealData?.creatorOfferKey && dealData?.firstPublishDate && dealData?.lastPublishDate) {
          const dealsForCurrentCreatorOfferKey = data.filter(d => d.creatorOfferKey === dealData.creatorOfferKey);
          if (dealsForCurrentCreatorOfferKey?.length > 1) {
            const nextDeal = dealsForCurrentCreatorOfferKey.find(d => d.firstPublishDate && d.firstPublishDate.isAfter(dealData.lastPublishDate));
            dealData.nextDealFirstPublishDate = nextDeal?.firstPublishDate || false;
            const previousDeal = dealsForCurrentCreatorOfferKey.reverse().find(d => d.lastPublishDate && d.lastPublishDate.isBefore(dealData.firstPublishDate));
            dealData.previousDealLastPublishDate = previousDeal?.lastPublishDate || false;
          }
        }
      });
    }
    return data;
  }

  const processDealsDataWithConversions = ({
    dealsData,
    existingCreatorBrandKeys,
    admins,
    allCPMConversionsInTimeframe,
    allAuraCPMConversionsInTimeframe,
    allConversionsInTimeframe,
    allCPMConversionsToDate,
    allAuraCPMConversionsToDate,
    allConversionsToDate,
    settings,
  }) => {
    console.log('settings:', settings);
    const countedDealIdsInTimeframe = new Set();
    const countedDealIdsToDate = new Set();
    let data = dealsData.filter(d => d.hasPublishedInTimeframe);

    data = spreadCPADealsMediaContent(data);

    // prepare deals data in selected timeframe
    data = data.reduce((acc, currentData) => {
      const {
        creatorBrandKey,
        typeOfDeal,
        deal,
        offer,
        creator,
        brand,
        publishDate: publishDateCPA,
        nextPublishDate: nextPublishDateCPA,
        firstPublishDate,
        firstPublishDateInTimeframe,
        nextDealFirstPublishDate,
        mediaContents,
      } = currentData;
      const creatorId = String(creator?._id);
      const offerId = String(offer?._id);
      const advertiserId = String(offer?.advertiser?._id);
      if (creatorId && offerId && advertiserId) {
        const isNew = creatorBrandKey ? !existingCreatorBrandKeys.has(creatorBrandKey) : true;
        const isCPM = typeOfDeal === dealTypes.upfrontCPM;
        const isCPA = typeOfDeal === dealTypes.cpa;
        const firstPublishHappenedInTimeframe = firstPublishDate && firstPublishDate.isBetween(startDateET, endDateET, undefined, '[]')
        const checkForWin = isCPA ? !!firstPublishDateInTimeframe : firstPublishHappenedInTimeframe;
        const isBetterHelpCPM = (brand === 'BetterHelp' && isCPM);
        const isAuraCPM = (brand === 'Aura' && isCPM);
        const affiliateId = creator?.affiliateDataObject?.tune_id;

        // check if we have next publishDate for current creator and offer within the selected timeframe
        // is used to filter conversions for current deal
        let publishDate = null;
        let endDateInTimeframe = endDateET;
        let endDateToDate = toDateET;
        if (isCPA) {
          if (publishDateCPA) {
            publishDate = publishDateCPA;
          }

          if (nextPublishDateCPA) {
            if (nextPublishDateCPA.isBefore(endDateET)) {
              endDateInTimeframe = nextPublishDateCPA;
            } else endDateInTimeframe = endDateET;
            endDateToDate = nextPublishDateCPA;
          } else {
            endDateInTimeframe = endDateET;
            endDateToDate = toDateET;
          }
        } else {
          if (firstPublishDate && firstPublishDate.isBefore(startDateET)) {
            publishDate = startDateET;
          } else if (firstPublishDateInTimeframe) {
            publishDate = firstPublishDateInTimeframe;
          }

          if (nextDealFirstPublishDate) {
            if (nextDealFirstPublishDate.isBefore(endDateET)) {
              endDateInTimeframe = nextDealFirstPublishDate;
            } else endDateInTimeframe = endDateET;
            endDateToDate = nextDealFirstPublishDate;
          } else {
            endDateInTimeframe = endDateET;
            endDateToDate = toDateET;
          }
        }

        if (publishDate) {
          if (nextDealFirstPublishDate) {
            if(nextDealFirstPublishDate.isBefore(endDateInTimeframe)) {
              endDateInTimeframe = nextDealFirstPublishDate;
            }
            if (nextDealFirstPublishDate.isBefore(endDateToDate)) {
              endDateInTimeframe = endDateToDate;
            }
          }
          // For hubildeals.dealTerms.type_of_deal : "Upfront CPM" AND hubildeals.dealTerms.brand : "BetterHelp", cpmconversions.action : "Paid"
          // For hubildeals.dealTerms.type_of_deal : "Upfront CPM" AND hubildeals.dealTerms.brand : "Aura", conversions.revenue : 0
          // For ALL OTHER DEALTYPES conversions.revenue : {$gt : 0 }

          let currentConversionsInTimeframe;
          if (isBetterHelpCPM) {
            currentConversionsInTimeframe = allCPMConversionsInTimeframe[creatorId]?.[advertiserId]?.filter(c => c.action === 'Paid'
              && moment.utc(c.activityDatetime).isBetween(publishDate, endDateInTimeframe, undefined, '[]')
            ) || [];
          } else if (isAuraCPM) {
            currentConversionsInTimeframe = allAuraCPMConversionsInTimeframe[affiliateId]?.filter(c => Number(c.offer_id) === Number(offer.tuneId)
              && c.revenue === 0
              && moment.utc(c.datetime).isBetween(publishDate, endDateInTimeframe, undefined, '[]')
            ) || [];
          } else {
            currentConversionsInTimeframe = allConversionsInTimeframe[affiliateId]?.filter(c => Number(c.offer_id) === Number(offer.tuneId)
              && c.revenue > 0
              && moment.utc(c.datetime).isBetween(publishDate, endDateInTimeframe, undefined, '[]')
            ) || [];
          }

          let currentConversionsToDate = [];
          if (isBetterHelpCPM) {
            currentConversionsToDate = allCPMConversionsToDate[creatorId]?.[advertiserId]?.filter(c => c.action === 'Paid'
              && moment.utc(c.activityDatetime).isBetween(publishDate, endDateToDate, undefined, '[]')
            ) || [];
          } else if (isAuraCPM) {
            currentConversionsToDate = allAuraCPMConversionsToDate[affiliateId]?.filter(c => Number(c.offer_id) === Number(offer.tuneId)
              && c.revenue === 0
              && moment.utc(c.datetime).isBetween(publishDate, endDateToDate, undefined, '[]')
            ) || [];
          } else {
            currentConversionsToDate = allConversionsToDate[affiliateId]?.filter(c => Number(c.offer_id) === Number(offer.tuneId)
              && c.revenue > 0
              && moment.utc(c.datetime).isBetween(publishDate, endDateToDate, undefined, '[]')
            ) || [];
          }

          let spendInTimeframe = 0;
          if (isCPM) {
            if (!countedDealIdsInTimeframe.has(String(deal._id)) && firstPublishDate && firstPublishDate.isSameOrAfter(startDateET)) {
              spendInTimeframe += (deal?.dealTerms?.bh_cpm_total_cost || 0);
              countedDealIdsInTimeframe.add(String(deal._id));
            }
          } else {
            if (!countedDealIdsInTimeframe.has(String(deal._id)) && firstPublishDate && firstPublishDate.isSameOrAfter(startDateET)) {
              spendInTimeframe += (deal?.dealTerms?.guaranteed_payment_contract_amount || 0);
              countedDealIdsInTimeframe.add(String(deal._id));
            }
            spendInTimeframe += currentConversionsInTimeframe.reduce((p, c) => {
              p += Number(c.payout || 0);
              return p;
            }, 0);
          }

          let spendToDate = 0;
          if (isCPM) {
            if (!countedDealIdsToDate.has(String(deal._id)) && firstPublishDate && firstPublishDate.isBetween(startDateET, endDateET, undefined, '[]')) {
              spendToDate += (deal?.dealTerms?.bh_cpm_total_cost || 0);
              countedDealIdsToDate.add(String(deal._id));
            }
          } else {
            if (!countedDealIdsToDate.has(String(deal._id)) && firstPublishDate && firstPublishDate.isBetween(startDateET, endDate, undefined, '[]')) {
              spendToDate += (deal?.dealTerms?.guaranteed_payment_contract_amount || 0);
              countedDealIdsToDate.add(String(deal._id));
            }
            spendToDate += currentConversionsToDate.reduce((p, c) => {
              p += Number(c.payout || 0);
              return p;
            }, 0);
          }

          let cacInTimeframe = null;
          let cacToDate = null;
          let positiveCacInTimeframe = false;
          let positiveCacToDate = false;
          const [targetCac, defaultTargetCac, targetCacPercentage] = getDefaultTargetCac(offer.advertiser, typeOfDeal, settings);

          if (isCPA) {
            const currentBrandCPASettings = settings.cpaSettings?.brands && settings.cpaSettings.brands[advertiserId];
            const diffDaysInTimeframe = endDateInTimeframe.diff(firstPublishDateInTimeframe, 'days');
            const diffDaysToDate = endDateToDate.diff(firstPublishDateInTimeframe, 'days');
            if (currentBrandCPASettings) {
              const dayRangeInTimeframe = Object.keys(currentBrandCPASettings.items).find(k => diffDaysInTimeframe <= Number(k));
              if (currentBrandCPASettings.items[dayRangeInTimeframe] && currentBrandCPASettings.items[dayRangeInTimeframe].value > 0 && currentConversionsInTimeframe.length >= currentBrandCPASettings.items[dayRangeInTimeframe].value) {
                positiveCacInTimeframe = true;
              }
              const dayRangeToDate = Object.keys(currentBrandCPASettings.items).find(k => diffDaysToDate <= Number(k));
              if (currentBrandCPASettings.items[dayRangeToDate] && currentBrandCPASettings.items[dayRangeToDate].value > 0 && currentConversionsToDate.length >= currentBrandCPASettings.items[dayRangeToDate].value) {
                positiveCacToDate = true;
              }
            }
          } else {
            if (currentConversionsInTimeframe?.length) cacInTimeframe = (spendInTimeframe || 0) / currentConversionsInTimeframe.length;
            if (currentConversionsToDate?.length) cacToDate = ((spendToDate || 0) / currentConversionsToDate.length);

            positiveCacInTimeframe = cacInTimeframe && targetCac >= cacInTimeframe;
            positiveCacToDate = cacToDate && targetCac >= cacToDate;
          }

          const getPredicted = () => {
            if (isBetterHelpCPM) {
              let positiveProjectedCac = false;

              const days = calculateDays({ mediaPublishedDate: publishDate });
              if (days < 16) {
                return {
                  positiveProjectedCac,
                  projectedPercent: 0,
                  projectedConversions: '-',
                  projectedCac: '-',
                  grossPar: '-',
                  netPar: '-',
                };
              }
              const projectedPercent = days > 0 ? getProjectedPercent(days, settings) : 0;
              const conversionsCount = currentConversionsToDate?.length || 0;
              const projectedConversions = (conversionsCount / projectedPercent) * 100;
              const projectedCac = projectedConversions ? (spendToDate / projectedConversions) : '-';
              positiveProjectedCac = projectedCac && targetCac >= projectedCac;
              const grossPar = (projectedCac !== '-' && projectedCac !== 0) ? ((targetCac / projectedCac) * spendToDate) : '-';
              const netPar = (grossPar !== '-' && grossPar !== 0) ? (grossPar / 1.15) : '-';

              return {
                positiveProjectedCac,
                projectedPercent,
                projectedConversions,
                projectedCac,
                grossPar,
                netPar,
              };
            }

            return {
              positiveProjectedCac: false,
              projectedPercent: 0,
              projectedConversions: '-',
              projectedCac: '-',
              grossPar: '-',
              netPar: '-',
            };
          };

          const {
            positiveProjectedCac,
            projectedPercent,
            projectedConversions,
            projectedCac,
            grossPar,
            netPar,
          } = getPredicted();

          let isWinningInTimeframe = false;
          let isWinningToDate = false;
          let isPredictedToWin = false;

          if (checkForWin) {
            let revenueInTimeframe = 0;
            if (isCPM) {
              revenueInTimeframe += (deal.dealTerms.bh_cpm_total_cost || 0);
            } else {
              revenueInTimeframe += currentConversionsInTimeframe.reduce((p, c) => {
                p += Number(c.revenue || 0);
                return p;
              }, 0);
            }
            let payoutInTimeframe = 0;
            if (firstPublishHappenedInTimeframe) payoutInTimeframe += (deal.dealTerms.guaranteed_payment_contract_amount || 0);
            if (isCPA) {
              payoutInTimeframe += currentConversionsInTimeframe.reduce((p, c) => {
                p += Number(c.payout || 0);
                return p;
              }, 0);
            }
            const grossProfitInTimeframe = revenueInTimeframe - payoutInTimeframe;

            let revenueToDate = 0;
            if (isCPM) {
              revenueToDate += (deal.dealTerms.bh_cpm_total_cost || 0);
            } else {
              revenueToDate += currentConversionsToDate.reduce((p, c) => {
                p += Number(c.revenue || 0);
                return p;
              }, 0);
            }
            let payoutToDate = 0;
            payoutToDate += deal.dealTerms.guaranteed_payment_contract_amount || 0;
            if (currentData.isCPA) payoutToDate += currentConversionsToDate.reduce((p, c) => {
              p += Number(c.payout || 0);
              return p;
            }, 0);
            const grossProfitToDate = revenueToDate - payoutToDate;
            // For hubildeals.dealtTerms.type_of_deal : "Upfront CPM", a winning creator is any creator who has a CAC <= target CAC
            // For all other dealstypes, a winning creator is any creator who has a gross profit > 0 using gross profit and conversions
            if (isCPM) {
              isWinningInTimeframe = positiveCacInTimeframe;
              isWinningToDate = positiveCacToDate;
              if (currentData.brand === 'BetterHelp') {
                isPredictedToWin = positiveProjectedCac;
              }
            } else {
              isWinningInTimeframe = grossProfitInTimeframe > 0 && currentConversionsInTimeframe.length > 0;
              isWinningToDate = grossProfitToDate > 0 && currentConversionsToDate.length > 0;
            }
          }

          const admin = admins.find(a => a.affiliateManagerId === creator?.affiliateDataObject?.account_manager_id);

          acc.push({
            ...currentData,
            isNew,
            checkForWin,
            isCPM,
            admin,
            publishDate,
            spendInTimeframe,
            spendToDate,
            conversionsInTimeframe: currentConversionsInTimeframe?.length || 0,
            conversionsToDate: currentConversionsToDate?.length || 0,
            numberOfPublishesOnDeal: isCPA ? '-' : (mediaContents?.length || '-'),
            targetCac,
            defaultTargetCac,
            targetCacPercentage,
            positiveCacInTimeframe,
            positiveCacToDate,
            cacInTimeframe,
            cacToDate,
            projectedPercent,
            projectedConversions,
            projectedCac,
            positiveProjectedCac,
            grossPar,
            netPar,
            isWinningInTimeframe,
            isWinningToDate,
            isPredictedToWin,
          });
        }
      }
      return acc;
    }, []);

    return data;
  };

  const processDealsDataForByCreatorView = (
    dealsData,
  ) => {
    const groupByCreatorBrandKey = dealsData.reduce((result, dealData) => {
      if (dealData.creatorBrandKey) {
        if (!result[dealData.creatorBrandKey]) result[dealData.creatorBrandKey] = { dealsData: [] };
        result[dealData.creatorBrandKey].dealsData.push(dealData);
      }
      return result;
    }, {});
    Object.keys(groupByCreatorBrandKey).forEach((key) => {
      const currentDealsData = groupByCreatorBrandKey[key].dealsData;
      const { creator, isNew } = currentDealsData[0];
      let latestDealData = null;
      let totalSpendInTimeframe = 0;
      let totalSpendToDate = 0;
      let totalConversionsInTimeframe = 0;
      let totalConversionsToDate = 0;
      currentDealsData.forEach((currentDealData) => {
        if (latestDealData) {
          if (currentDealData.publishDate.isAfter(latestDealData.publishDate)) latestDealData = currentDealData;
        } else latestDealData = currentDealData;
        totalSpendInTimeframe += currentDealData.spendInTimeframe || 0;
        totalSpendToDate += currentDealData.spendToDate || 0;

        totalConversionsInTimeframe += currentDealData.conversionsInTimeframe;
        totalConversionsToDate += currentDealData.conversionsToDate;
      });

      const cacInTimeframe = totalConversionsInTimeframe > 0 ? (totalSpendInTimeframe / totalConversionsInTimeframe) : '-';
      const cacToDate = totalConversionsToDate > 0 ? (totalSpendToDate / totalConversionsToDate) : '-';
      const { targetCac } = latestDealData;

      const positiveCacInTimeframe = cacInTimeframe !== '-' && cacInTimeframe > 0 && targetCac >= cacInTimeframe;
      const positiveCacToDate = cacToDate !== '-' && cacToDate > 0 && targetCac >= cacToDate;

      groupByCreatorBrandKey[key].creator = creator;
      groupByCreatorBrandKey[key].isNew = isNew;
      groupByCreatorBrandKey[key].latestDealData = latestDealData;
      groupByCreatorBrandKey[key].totalSpendInTimeframe = totalSpendInTimeframe;
      groupByCreatorBrandKey[key].totalSpendToDate = totalSpendToDate;
      groupByCreatorBrandKey[key].positiveCacInTimeframe = positiveCacInTimeframe;
      groupByCreatorBrandKey[key].positiveCacToDate = positiveCacToDate;
      groupByCreatorBrandKey[key].totalConversionsInTimeframe = totalConversionsInTimeframe;
      groupByCreatorBrandKey[key].totalConversionsToDate = totalConversionsToDate;
      groupByCreatorBrandKey[key].cacInTimeframe = totalConversionsInTimeframe > 0 ? (totalSpendInTimeframe / totalConversionsInTimeframe) : '-';
      groupByCreatorBrandKey[key].cacToDate = totalConversionsToDate > 0 ? (totalSpendToDate / totalConversionsToDate) : '-';
    });

    return Object.values(groupByCreatorBrandKey);
  };

  const getDealsSpendInTimeframe = (data, existingCreatorBrandKeys) => {
    let newSpend = 0;
    let existingSpend = 0;
    const dataInTimeframe = data.filter(d => d.firstPublishDate && d.firstPublishDate.isBetween(startDateET, endDateET, undefined, '[]'));
    if (dataInTimeframe.length > 0) {
      const countedDealIds = new Set();
      dataInTimeframe.forEach(d => {
        if (d?.deal?._id && !countedDealIds.has(String(d.deal._id)) && d.deal.dealTerms) {
          const isExisting = d?.creatorBrandKey ? existingCreatorBrandKeys.has(d?.creatorBrandKey) : false;
          if (d.typeOfDeal === dealTypes.upfrontCPM && d.deal.dealTerms.bh_cpm_total_cost) {
            if (isExisting) {
              existingSpend += d.deal.dealTerms.bh_cpm_total_cost;
            } else newSpend += d.deal.dealTerms.bh_cpm_total_cost;
          } else if (d.deal.dealTerms.guaranteed_payment_contract_amount) {
            if (isExisting) {
              existingSpend += d.deal.dealTerms.guaranteed_payment_contract_amount;
            } else newSpend += d.deal.dealTerms.guaranteed_payment_contract_amount;
          }
          countedDealIds.add(String(d.deal._id));
        }
      })
    }
    return { newSpend, existingSpend };
  }

  const getSortedMediaContent = (data) => {
    const { mcBeforeStartDate, mcInTimeframe, mcToDate } = data.reduce((acc, cur) => {
      if (cur.mediaContentBeforeStartDate?.length > 0) acc.mcBeforeStartDate = acc.mcBeforeStartDate.concat(cur.mediaContentBeforeStartDate);
      if (cur.mediaContentInTimeframe?.length > 0) acc.mcInTimeframe = acc.mcInTimeframe.concat(cur.mediaContentInTimeframe);
      if (cur.mediaContentToDate?.length > 0) acc.mcToDate = acc.mcToDate.concat(cur.mediaContentToDate);
      return acc;
    }, { mcBeforeStartDate: [], mcInTimeframe: [], mcToDate: [] })
    mcBeforeStartDate.sort(compareMediaContentsPublishDatesAsc);
    mcInTimeframe.sort(compareMediaContentsPublishDatesAsc);
    mcToDate.sort(compareMediaContentsPublishDatesAsc);
    return { mediaContentBeforeStartDate: mcBeforeStartDate, mediaContentInTimeframe: mcInTimeframe, mediaContentToDate: mcToDate };
  }

  const getCreatorBrandKeyFromMediaContent = (mediaContent) => {
    if (mediaContent.influencer && mediaContent.advertiser) {
      return `${String(mediaContent.influencer)}-${String(mediaContent.advertiser)}`;
    }
    return false;
  }

  const getCreatorBrandKeysSetFromMediaContents = (mediaContents) => {
    const keys = new Set();
    mediaContents.forEach((mediaContent) => {
      const key = getCreatorBrandKeyFromMediaContent(mediaContent);
      if (key) {
        keys.add(key);
      }
    });
    return keys;
  }

  const getActiveCreatorsAndConversionsCountAndSpend = (
    dealsData,
    existingCreatorBrandKeys,
    advertisers,
    creators,
    offers,
    allCPMConversions = {},
    allAuraCPMConversions = {},
    allConversions = {},
    isInTimeframe = true,
  ) => {
    const dealsDataReversed = [...dealsData].reverse();
    const newKeys = new Set();
    const existingKeys = new Set();
    let newConversions = 0;
    let existingConversions = 0;
    let newConversionsSpend = 0;
    let existingConversionsSpend = 0;
    const conversionIds = new Set();
    let includeUpfrontCPMConversions;
    const upfrontCPMDealTypeOption = selectedDealType.find(o => o.value === 'Upfront CPM');
    includeUpfrontCPMConversions = selectedDealType?.length === 0 || upfrontCPMDealTypeOption;


    if (includeUpfrontCPMConversions) {
      Object.keys(allCPMConversions).forEach((currentCreatorId) => {
        if (allCPMConversions[currentCreatorId]) {
          Object.keys(allCPMConversions[currentCreatorId]).forEach((currentBrandId) => {
            if (allCPMConversions[currentCreatorId][currentBrandId]?.length > 0) {
              const key = `${currentCreatorId}-${currentBrandId}`;
              let currentStartDate = false;
              let currentEndDate = (isInTimeframe ? endDateET : toDateET);

              const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
                && d.typeOfDeal === dealTypes.upfrontCPM
                && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
              );
              if (firstDealInTimeframe) {
                currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                  ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                    ? startDateET
                    : firstDealInTimeframe.previousDealLastPublishDate)
                  : firstDealInTimeframe.firstPublishDate;
              } else {
                const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                  && d.typeOfDeal === dealTypes.upfrontCPM
                  && d.firstPublishDate
                );
                if (latestDeal) {
                  if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = startDateET;
                  } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = latestDeal.firstPublishDate;
                  }
                }
              }

              if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
                const conversionsForDeal = allCPMConversions[currentCreatorId][currentBrandId].filter(c => c.action === 'Paid'
                  && moment.utc(c.activityDatetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
                if (conversionsForDeal?.length > 0) {
                  conversionsForDeal.forEach(c => {
                    if (c._id && !conversionIds.has(String(c._id))) {
                      conversionIds.add(String(c._id));
                    }
                  });

                  if (existingCreatorBrandKeys.has(key)) {
                    existingKeys.add(key);
                    existingConversions += conversionsForDeal.length;
                  } else {
                    newKeys.add(key);
                    newConversions += conversionsForDeal.length;
                  }
                }
              }
            }
          });
        }
      });
    }

    const aura = advertisers.find(a => a.companyName === 'Aura');
    if (includeUpfrontCPMConversions && aura?._id) {
      Object.keys(allAuraCPMConversions).forEach((currentCreatorTuneId) => {
        if (allAuraCPMConversions[currentCreatorTuneId]) {
          const currentCreator = creators.find(c => c?.affiliateDataObject?.tune_id && String(c.affiliateDataObject.tune_id) === String(currentCreatorTuneId));
          if (currentCreator?._id) {
            const brandOffers = Object.values(offers)
              .filter(offer => String(offer.advertiser?._id) === String(aura._id));
            const brandOfferTuneIds = brandOffers?.map(offer => offer.tuneId) || [];
            const brandCPAConversions = allAuraCPMConversions[currentCreatorTuneId]?.filter(c => (c.advertiser_id
                && Number(c.advertiser_id) && Number(c.advertiser_id) === Number(aura.tuneId))
              || (brandOfferTuneIds.length > 0 && Number(c.offer_id) && brandOfferTuneIds.includes(Number(c.offer_id))));

            if (brandCPAConversions?.length > 0) {
              const key = `${String(currentCreator._id)}-${String(aura._id)}`;
              let currentStartDate = false;
              let currentEndDate = (isInTimeframe ? endDateET : toDateET);

              const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
                && d.typeOfDeal === dealTypes.upfrontCPM
                && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
              );
              if (firstDealInTimeframe) {
                currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                  ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                    ? startDateET
                    : firstDealInTimeframe.previousDealLastPublishDate)
                  : firstDealInTimeframe.firstPublishDate;
              } else {
                const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                  && d.typeOfDeal === dealTypes.upfrontCPM
                  && d.firstPublishDate
                );
                if (latestDeal) {
                  if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = startDateET;
                  } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = latestDeal.firstPublishDate;
                  }
                }
              }

              if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
                const conversionsForDeal = brandCPAConversions.filter(c => c.revenue === 0
                  && moment.utc(c.datetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
                if (conversionsForDeal?.length > 0) {
                  conversionsForDeal.forEach(c => {
                    if (c._id && !conversionIds.has(String(c._id))) {
                      conversionIds.add(String(c._id));
                    }
                  });
                  if (existingCreatorBrandKeys.has(key)) {
                    existingKeys.add(key);
                    existingConversions += conversionsForDeal.length;
                  } else {
                    newKeys.add(key);
                    newConversions += conversionsForDeal.length;
                  }
                }
              }
            }
          }
        }
      });
    }

    Object.keys(allConversions).forEach((currentCreatorTuneId) => {
      if (allConversions[currentCreatorTuneId]) {
        const currentCreator = creators.find(c => c?.affiliateDataObject?.tune_id && String(c.affiliateDataObject.tune_id) === String(currentCreatorTuneId));
        if (currentCreator?._id) {
          advertisers.filter(advertiser => advertiser.tuneId).forEach((currentBrand) => {
            if (currentBrand?._id) {
              const brandOffers = Object.values(offers)
                .filter(offer => String(offer.advertiser?._id) === String(currentBrand._id));
              const brandOfferTuneIds = brandOffers?.map(offer => offer.tuneId) || [];
              const brandCPAConversions = allConversions[currentCreatorTuneId]?.filter(c => (c.advertiser_id
                  && Number(c.advertiser_id) && Number(c.advertiser_id) === Number(currentBrand.tuneId))
                || (brandOfferTuneIds.length > 0 && Number(c.offer_id) && brandOfferTuneIds.includes(Number(c.offer_id))));

              if (brandCPAConversions?.length > 0) {
                const key = `${String(currentCreator._id)}-${String(currentBrand._id)}`;
                let spend = 0;
                let currentStartDate = false;
                let currentEndDate = (isInTimeframe ? endDateET : toDateET);

                const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
                  && d.typeOfDeal !== dealTypes.upfrontCPM
                  && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
                );
                if (firstDealInTimeframe) {
                  currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                    ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                      ? startDateET
                      : firstDealInTimeframe.previousDealLastPublishDate)
                    : firstDealInTimeframe.firstPublishDate;
                } else {
                  const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                    && d.typeOfDeal !== dealTypes.upfrontCPM
                    && d.firstPublishDate
                  );
                  if (latestDeal) {
                    if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                      currentStartDate = startDateET;
                    } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                      currentStartDate = latestDeal.firstPublishDate;
                    }
                  }
                }

                if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
                  const conversionsForDeal = brandCPAConversions.filter(c => c.revenue > 0
                    && moment.utc(c.datetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
                  if (conversionsForDeal?.length > 0) {
                    conversionsForDeal.forEach(c => {
                      if (c._id && !conversionIds.has(String(c._id))) {
                        conversionIds.add(String(c._id));
                        spend += Number(c.payout || 0);
                      }
                    });
                    if (existingCreatorBrandKeys.has(key)) {
                      existingKeys.add(key);
                      existingConversions += conversionsForDeal.length;
                      existingConversionsSpend += spend;
                    } else {
                      newKeys.add(key);
                      newConversions += conversionsForDeal.length;
                      newConversionsSpend += spend;
                    }
                  }
                }
              }
            }
          });
        }
      }
    });

    return {
      newActiveCreatorsCount: newKeys?.size || 0,
      existingActiveCreatorsCount: existingKeys?.size || 0,
      newConversions,
      existingConversions,
      newConversionsSpend,
      existingConversionsSpend,
    };
  };

  const getPublishedCreatorsCount = (data, existingCreatorBrandKeys) => {
    const newKeys = new Set();
    const existingKeys = new Set();
    const publishedCreatorsBrandKeys = [...new Set(data.filter(d => d.hasPublishedInTimeframe && d.creatorBrandKey).map(d => d.creatorBrandKey))];
    publishedCreatorsBrandKeys.forEach((key) => {
      if (existingCreatorBrandKeys.has(key)) {
        existingKeys.add(key);
      } else newKeys.add(key);
    });

    return {
      newPublishedCreatorsCount: newKeys?.size || 0,
      existingPublishedCreatorsCount: existingKeys?.size || 0,
    };
  }

  const calculateTimeframeSummary = (
    dealsData,
    existingCreatorBrandKeys,
    advertisers,
    creators,
    offers,
    allCPMConversionsInTimeframe,
    allAuraCPMConversionsInTimeframe,
    allConversionsInTimeframe,
    allCPMConversionsToDate,
    allAuraCPMConversionsToDate,
    allConversionsToDate,
  ) => {
    const {
      newActiveCreatorsCount,
      existingActiveCreatorsCount,
      newConversions: newConversionsInTimeframe,
      existingConversions: existingConversionsInTimeframe,
      newConversionsSpend: newConversionsSpendInTimeframe,
      existingConversionsSpend: existingConversionsSpendInTimeframe,
    } = getActiveCreatorsAndConversionsCountAndSpend(
      dealsData,
      existingCreatorBrandKeys,
      advertisers,
      creators,
      offers,
      allCPMConversionsInTimeframe,
      allAuraCPMConversionsInTimeframe,
      allConversionsInTimeframe,
    );
    // console.log('newActiveCreatorsCount: ', newActiveCreatorsCount);
    // console.log('existingActiveCreatorsCount: ', existingActiveCreatorsCount);
    // console.log('newConversionsInTimeframe: ', newConversionsInTimeframe);
    // console.log('existingConversionsInTimeframe: ', existingConversionsInTimeframe);
    // console.log('newConversionsSpendInTimeframe: ', newConversionsSpendInTimeframe);
    // console.log('existingConversionsSpendInTimeframe: ', existingConversionsSpendInTimeframe);

    const {
      newConversions: newConversionsToDate,
      existingConversions: existingConversionsToDate,
    } = getActiveCreatorsAndConversionsCountAndSpend(
      dealsData,
      existingCreatorBrandKeys,
      advertisers,
      creators,
      offers,
      allCPMConversionsToDate,
      allAuraCPMConversionsToDate,
      allConversionsToDate,
      false,
    );
    // console.log('newConversionsToDate: ', newConversionsToDate);
    // console.log('existingConversionsToDate: ', existingConversionsToDate);

    const { newPublishedCreatorsCount, existingPublishedCreatorsCount } = getPublishedCreatorsCount(dealsData, existingCreatorBrandKeys);
    // console.log('newPublishedCreatorsCount: ', newPublishedCreatorsCount);
    // console.log('existingPublishedCreatorsCount: ', existingPublishedCreatorsCount);

    const { newSpend, existingSpend } = getDealsSpendInTimeframe(dealsData, existingCreatorBrandKeys);
    // console.log('newSpend: ', newSpend);
    // console.log('existingSpend: ', existingSpend);

    const totalNewSpendInTimeframe = newSpend + newConversionsSpendInTimeframe;
    const totalExistingSpendInTimeframe = existingSpend + existingConversionsSpendInTimeframe;
    // console.log('totalNewSpendInTimeframe: ', totalNewSpendInTimeframe);
    // console.log('totalExistingSpendInTimeframe: ', totalExistingSpendInTimeframe);

    const timeframeSummaryStatistics = [
      {
        stat: 'Active Creators',
        new: formatIntNumber(newActiveCreatorsCount),
        existing: formatIntNumber(existingActiveCreatorsCount),
        total: formatIntNumber(newActiveCreatorsCount + existingActiveCreatorsCount),
      },
      {
        stat: 'Creators Published',
        new: formatIntNumber(newPublishedCreatorsCount),
        existing: formatIntNumber(existingPublishedCreatorsCount),
        total: formatIntNumber(newPublishedCreatorsCount + existingPublishedCreatorsCount),
      },
      {
        stat: 'Revenue Conversions In Timeframe',
        new: formatIntNumber(newConversionsInTimeframe),
        existing: formatIntNumber(existingConversionsInTimeframe),
        total: formatIntNumber(newConversionsInTimeframe + existingConversionsInTimeframe),
      },
      {
        stat: 'Revenue Conversions To Date',
        new: formatIntNumber(newConversionsToDate),
        existing: formatIntNumber(existingConversionsToDate),
        total: formatIntNumber(newConversionsToDate + existingConversionsToDate),
      },
      {
        stat: 'Spend',
        new: formatCurrency(totalNewSpendInTimeframe),
        existing: formatCurrency(totalExistingSpendInTimeframe),
        total: formatCurrency(totalNewSpendInTimeframe + totalExistingSpendInTimeframe),
      },
    ];
    // console.log('timeframeSummaryStatistics: ', timeframeSummaryStatistics);
    setTimeframeSummary(timeframeSummaryStatistics);
  };

  const calculateWinnersSummary = (data) => {
    let dealsData = data.filter(d => d?.checkForWin);
    const newDealsData = dealsData.filter(d => d?.isNew);
    const existingDealsData = dealsData.filter(d => !d?.isNew);

    const getWinningCAC = (data, timeframe) => {
      if (data) {
        let key1 = false;
        let key2 = false;
        let key3 = false;
        if (timeframe === 'inTimeframe') {
          key1 = 'isWinningInTimeframe';
          key2 = 'spendInTimeframe';
          key3 = 'conversionsInTimeframe';
        }
        if (timeframe === 'toDate') {
          key1 = 'isWinningToDate';
          key2 = 'spendToDate';
          key3 = 'conversionsToDate';
        }
        if (timeframe === 'predicted') {
          key1 = 'isPredictedToWin';
          key2 = 'spendToDate';
          key3 = 'projectedConversions';
        }
        if (key1 && key2 && key3) {
          const conversionsCount = data.reduce((acc, cur) => (cur[key1] ? (acc + cur[key3]) : acc), 0);
          if (conversionsCount > 0) {
            const spend = data.reduce((acc, cur) => (cur[key1] ? (acc + cur[key2]) : acc), 0);
            const cac = spend / conversionsCount;
            return formatCurrency(cac);
          }
          return '-';
        }
        return '-';
      }
      return '-';
    };

    const getPercentOfSpendWinning = (data, timeframe) => {
      if (data) {
        let key1 = false;
        let key2 = false;
        if (timeframe === 'inTimeframe') {
          key1 = 'isWinningInTimeframe';
          key2 = 'spendInTimeframe';
        }
        if (timeframe === 'toDate') {
          key1 = 'isWinningToDate';
          key2 = 'spendToDate';
        }
        if (timeframe === 'predicted') {
          key1 = 'isPredictedToWin';
          key2 = 'spendInTimeframe';
        }
        if (key1 && key2) {
          const spendWinning = data.reduce((acc, cur) => (cur[key1] ? (acc + cur[key2]) : acc), 0);
          if (spendWinning > 0) {
            const spend = data.reduce((acc, cur) => (acc + cur[key2]), 0);
            if (spend > 0) {
              const percent = (spendWinning / spend) * 100;
              return `${formatIntNumber(percent)}%`;
            }
            return '-';
          }
          return '-';
        }
        return '-';
      }
      return '-';
    };

    const getPercentOfCreatorsWinning = (data, timeframe) => {
      if (data) {
        let key = false;
        if (timeframe === 'inTimeframe') {
          key = 'isWinningInTimeframe';
        }
        if (timeframe === 'toDate') {
          key = 'isWinningToDate';
        }
        if (timeframe === 'predicted') {
          key = 'isPredictedToWin';
        }
        if (key) {
          const creatorsSet = new Set();
          const creatorsWinningSet = new Set();
          data.forEach((d) => {
            if (d.creatorBrandKey) {
              creatorsSet.add(d.creatorBrandKey);
              if (d[key]) {
                creatorsWinningSet.add(d.creatorBrandKey);
              }
            }
          });
          if (creatorsSet?.size > 0 && creatorsWinningSet?.size > 0) {
            const percent = (creatorsWinningSet.size / creatorsSet.size) * 100;
            return `${formatIntNumber(percent)}%`;
          }
          return '-';
        }
        return '-';
      }
      return '-';
    };

    const winnersSummaryStatistics = [
      {
        stat: 'Winning Spend',
        newInTimeframe: formatCurrency(newDealsData.reduce((acc, cur) => (cur.isWinningInTimeframe ? (acc + cur.spendInTimeframe) : acc), 0)),
        existingInTimeframe: formatCurrency(existingDealsData.reduce((acc, cur) => (cur.isWinningInTimeframe ? (acc + cur.spendInTimeframe) : acc), 0)),
        newToDate: formatCurrency(newDealsData.reduce((acc, cur) => (cur.isWinningToDate ? (acc + cur.spendToDate) : acc), 0)),
        existingToDate: formatCurrency(existingDealsData.reduce((acc, cur) => (cur.isWinningToDate ? (acc + cur.spendToDate) : acc), 0)),
        newPredicted: formatCurrency(newDealsData.reduce((acc, cur) => (cur.isPredictedToWin ? (acc + cur.spendInTimeframe) : acc), 0)),
        existingPredicted: formatCurrency(existingDealsData.reduce((acc, cur) => (cur.isPredictedToWin ? (acc + cur.spendInTimeframe) : acc), 0)),
      },
      {
        stat: 'Winning Creators',
        newInTimeframe: formatIntNumber(newDealsData.reduce((acc, cur) => (cur.isWinningInTimeframe ? (acc + 1) : acc), 0)),
        existingInTimeframe: formatIntNumber(existingDealsData.reduce((acc, cur) => (cur.isWinningInTimeframe ? (acc + 1) : acc), 0)),
        newToDate: formatIntNumber(newDealsData.reduce((acc, cur) => (cur.isWinningToDate ? (acc + 1) : acc), 0)),
        existingToDate: formatIntNumber(existingDealsData.reduce((acc, cur) => (cur.isWinningToDate ? (acc + 1) : acc), 0)),
        newPredicted: formatIntNumber(newDealsData.reduce((acc, cur) => (cur.isPredictedToWin ? (acc + 1) : acc), 0)),
        existingPredicted: formatIntNumber(existingDealsData.reduce((acc, cur) => (cur.isPredictedToWin ? (acc + 1) : acc), 0)),
      },
      {
        stat: 'Winning Revenue Conversions',
        newInTimeframe: formatIntNumber(newDealsData.reduce((acc, cur) => (cur.isWinningInTimeframe ? (acc + cur.conversionsInTimeframe) : acc), 0)),
        existingInTimeframe: formatIntNumber(existingDealsData.reduce((acc, cur) => (cur.isWinningInTimeframe ? (acc + cur.conversionsInTimeframe) : acc), 0)),
        newToDate: formatIntNumber(newDealsData.reduce((acc, cur) => (cur.isWinningToDate ? (acc + cur.conversionsToDate) : acc), 0)),
        existingToDate: formatIntNumber(existingDealsData.reduce((acc, cur) => (cur.isWinningToDate ? (acc + cur.conversionsToDate) : acc), 0)),
        newPredicted: formatIntNumber(newDealsData.reduce((acc, cur) => (cur.isPredictedToWin ? (acc + (Number(cur.projectedConversions) || 0)) : acc), 0), 0),
        existingPredicted: formatIntNumber(existingDealsData.reduce((acc, cur) => (cur.isPredictedToWin ? (acc + (Number(cur.projectedConversions) || 0)) : acc), 0), 0),
      },
      {
        stat: 'Winning CAC',
        newInTimeframe: getWinningCAC(newDealsData, 'inTimeframe'),
        existingInTimeframe: getWinningCAC(existingDealsData, 'inTimeframe'),
        newToDate: getWinningCAC(newDealsData, 'toDate'),
        existingToDate: getWinningCAC(existingDealsData, 'toDate'),
        newPredicted: getWinningCAC(newDealsData, 'predicted'),
        existingPredicted: getWinningCAC(existingDealsData, 'predicted'),
      },
      {
        stat: 'Percent of Spend Winning',
        newInTimeframe: getPercentOfSpendWinning(newDealsData, 'inTimeframe'),
        existingInTimeframe: getPercentOfSpendWinning(existingDealsData, 'inTimeframe'),
        newToDate: getPercentOfSpendWinning(newDealsData, 'toDate'),
        existingToDate: getPercentOfSpendWinning(existingDealsData, 'toDate'),
        newPredicted: getPercentOfSpendWinning(newDealsData, 'predicted'),
        existingPredicted: getPercentOfSpendWinning(existingDealsData, 'predicted'),
      },
      {
        stat: 'Percent of Creators Winning',
        newInTimeframe: getPercentOfCreatorsWinning(newDealsData, 'inTimeframe'),
        existingInTimeframe: getPercentOfCreatorsWinning(existingDealsData, 'inTimeframe'),
        newToDate: getPercentOfCreatorsWinning(newDealsData, 'toDate'),
        existingToDate: getPercentOfCreatorsWinning(existingDealsData, 'toDate'),
        newPredicted: getPercentOfCreatorsWinning(newDealsData, 'predicted'),
        existingPredicted: getPercentOfCreatorsWinning(existingDealsData, 'predicted'),
      },
    ];

    // console.log('winnersSummaryStatistics: ', winnersSummaryStatistics);
    setWinnersSummary(winnersSummaryStatistics);
  }

  const getStatsDataByDates = ({
    dealsData,
    creators,
    advertisers,
    offers,
    existingCreatorBrandKeys,
    mediaContentInTimeframe,
    allCPMConversionsInTimeframe,
    allAuraCPMConversionsInTimeframe,
    allConversionsInTimeframe,
    allCPMConversionsToDate,
    allAuraCPMConversionsToDate,
    allConversionsToDate,
  }) => {
    const dealsDataReversed = [...dealsData].reverse();
    const graphsData = {
      activeCreators: {},
      creatorsPublished: {},
      conversionsInTimeframe: {},
      conversionsToDate: {},
      spend: {},
    };

    mediaContentInTimeframe.forEach((mediaContent) => {
      const key = getCreatorBrandKeyFromMediaContent(mediaContent);
      const isNew = !existingCreatorBrandKeys.has(key);
      const label = mediaContent.publishDate.format('YYYY-MM-DD');
      if (!graphsData.creatorsPublished[label]) {
        graphsData.creatorsPublished[label] = {
          new: new Set(),
          existing: new Set(),
        }
      }
      graphsData.creatorsPublished[label][`${isNew ? 'new' : 'existing'}`].add(key);
    });

    const dealsDataTimeframe = dealsData.filter(d => d.firstPublishDate && d.firstPublishDate.isBetween(startDateET, endDateET, undefined, '[]'));
    if (dealsDataTimeframe.length > 0) {
      const countedDealIds = new Set();
      dealsDataTimeframe.forEach(d => {
        if (d?.deal?._id && !countedDealIds.has(String(d.deal._id)) && d.creatorBrandKey && d.deal.dealTerms) {
          const isNew = !existingCreatorBrandKeys.has(d.creatorBrandKey);
          const label = d.firstPublishDate.format('YYYY-MM-DD');
          if (!graphsData.spend[label]) {
            graphsData.spend[label] = {
              new: 0,
              existing: 0,
            }
          }
          let spend = 0;
          if (d.typeOfDeal === dealTypes.upfrontCPM && d.deal.dealTerms.bh_cpm_total_cost) {
            spend += d.deal.dealTerms.bh_cpm_total_cost;
          } else if (d.deal.dealTerms.guaranteed_payment_contract_amount) {
            spend += d.deal.dealTerms.guaranteed_payment_contract_amount;
          }
          graphsData.spend[label][`${isNew ? 'new' : 'existing'}`] += spend;
          countedDealIds.add(String(d.deal._id));
        }
      })
    }

    const aura = advertisers.find(a => a.companyName === 'Aura');
    let conversionIdsInTimeframe = new Set();
    let conversionIdsToDate = new Set();
    let includeUpfrontCPMConversions;
    const upfrontCPMDealTypeOption = selectedDealType.find(o => o.value === 'Upfront CPM');
    includeUpfrontCPMConversions = selectedDealType?.length === 0 || upfrontCPMDealTypeOption;

    if (includeUpfrontCPMConversions) {
      Object.keys(allCPMConversionsInTimeframe).forEach((currentCreatorId) => {
        if (allCPMConversionsInTimeframe[currentCreatorId]) {
          Object.keys(allCPMConversionsInTimeframe[currentCreatorId]).forEach((currentBrandId) => {
            if (allCPMConversionsInTimeframe[currentCreatorId][currentBrandId]?.length > 0) {
              const key = `${currentCreatorId}-${currentBrandId}`;
              const isNew = !existingCreatorBrandKeys.has(key);
              let currentStartDate = false;
              let currentEndDate = endDateET;

              const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
                && d.typeOfDeal === dealTypes.upfrontCPM
                && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
              );
              if (firstDealInTimeframe) {
                currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                  ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                    ? startDateET
                    : firstDealInTimeframe.previousDealLastPublishDate)
                  : firstDealInTimeframe.firstPublishDate;
              } else {
                const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                  && d.typeOfDeal === dealTypes.upfrontCPM
                  && d.firstPublishDate
                );
                if (latestDeal) {
                  if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = startDateET;
                  } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = latestDeal.firstPublishDate;
                  }
                }
              }

              if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
                const conversionsForDeal = allCPMConversionsInTimeframe[currentCreatorId][currentBrandId].filter(c => c.action === 'Paid'
                  && moment.utc(c.activityDatetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
                if (conversionsForDeal?.length > 0) {
                  conversionsForDeal.forEach(c => {
                    if (c._id && !conversionIdsInTimeframe.has(String(c._id))) {
                      const label = moment.utc(c.activityDatetime).tz('America/New_York').format('YYYY-MM-DD');

                      if (!graphsData.conversionsInTimeframe[label]) {
                        graphsData.conversionsInTimeframe[label] = {
                          new: new Set(),
                          existing: new Set(),
                        }
                      }
                      graphsData.conversionsInTimeframe[label][`${isNew ? 'new' : 'existing'}`].add(String(c._id));

                      if (!graphsData.activeCreators[label]) {
                        graphsData.activeCreators[label] = {
                          new: new Set(),
                          existing: new Set(),
                        }
                      }
                      graphsData.activeCreators[label][`${isNew ? 'new' : 'existing'}`].add(key);

                      conversionIdsInTimeframe.add(String(c._id));
                    }
                  });
                }
              }
            }
          });
        }
      });
    }

    if (includeUpfrontCPMConversions && aura?._id) {
      Object.keys(allAuraCPMConversionsInTimeframe).forEach((currentCreatorTuneId) => {
        if (allAuraCPMConversionsInTimeframe[currentCreatorTuneId]) {
          const currentCreator = creators.find(c => c?.affiliateDataObject?.tune_id && String(c.affiliateDataObject.tune_id) === String(currentCreatorTuneId));
          if (currentCreator?._id) {
            const brandOffers = Object.values(offers)
              .filter(offer => String(offer.advertiser?._id) === String(aura._id));
            const brandOfferTuneIds = brandOffers?.map(offer => offer.tuneId) || [];
            const brandCPAConversions = allAuraCPMConversionsInTimeframe[currentCreatorTuneId]?.filter(c => (c.advertiser_id
                && Number(c.advertiser_id) && Number(c.advertiser_id) === Number(aura.tuneId))
              || (brandOfferTuneIds.length > 0 && Number(c.offer_id) && brandOfferTuneIds.includes(Number(c.offer_id))));

            if (brandCPAConversions?.length > 0) {
              const key = `${String(currentCreator._id)}-${String(aura._id)}`;
              const isNew = !existingCreatorBrandKeys.has(key);
              let currentStartDate = false;
              let currentEndDate = endDateET;

              const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
                && d.typeOfDeal === dealTypes.upfrontCPM
                && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
              );
              if (firstDealInTimeframe) {
                currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                  ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                    ? startDateET
                    : firstDealInTimeframe.previousDealLastPublishDate)
                  : firstDealInTimeframe.firstPublishDate;
              } else {
                const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                  && d.typeOfDeal === dealTypes.upfrontCPM
                  && d.firstPublishDate
                );
                if (latestDeal) {
                  if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = startDateET;
                  } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = latestDeal.firstPublishDate;
                  }
                }
              }

              if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
                const conversionsForDeal = brandCPAConversions.filter(c => c.revenue === 0
                  && moment.utc(c.datetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
                if (conversionsForDeal?.length > 0) {
                  conversionsForDeal.forEach(c => {
                    if (c._id && !conversionIdsInTimeframe.has(String(c._id))) {
                      const label = moment.tz(moment.utc(c.datetime), 'America/New_York').format('YYYY-MM-DD');

                      if (!graphsData.conversionsInTimeframe[label]) {
                        graphsData.conversionsInTimeframe[label] = {
                          new: new Set(),
                          existing: new Set(),
                        }
                      }
                      graphsData.conversionsInTimeframe[label][`${isNew ? 'new' : 'existing'}`].add(String(c._id));

                      if (!graphsData.activeCreators[label]) {
                        graphsData.activeCreators[label] = {
                          new: new Set(),
                          existing: new Set(),
                        }
                      }
                      graphsData.activeCreators[label][`${isNew ? 'new' : 'existing'}`].add(key);

                      conversionIdsInTimeframe.add(String(c._id));
                    }
                  });
                }
              }
            }
          }
        }
      });
    }

    Object.keys(allConversionsInTimeframe).forEach((currentCreatorTuneId) => {
      if (allConversionsInTimeframe[currentCreatorTuneId]) {
        const currentCreator = creators.find(c => c?.affiliateDataObject?.tune_id && String(c.affiliateDataObject.tune_id) === String(currentCreatorTuneId));
        if (currentCreator?._id) {
          advertisers.filter(advertiser => advertiser.tuneId).forEach((currentBrand) => {
            if (currentBrand?._id) {
              const brandOffers = Object.values(offers)
                .filter(offer => String(offer.advertiser?._id) === String(currentBrand._id));
              const brandOfferTuneIds = brandOffers?.map(offer => offer.tuneId) || [];
              const brandCPAConversions = allConversionsInTimeframe[currentCreatorTuneId]?.filter(c => (c.advertiser_id
                  && Number(c.advertiser_id) && Number(c.advertiser_id) === Number(currentBrand.tuneId))
                || (brandOfferTuneIds.length > 0 && Number(c.offer_id) && brandOfferTuneIds.includes(Number(c.offer_id))));

              if (brandCPAConversions?.length > 0) {
                const key = `${String(currentCreator._id)}-${String(currentBrand._id)}`;
                const isNew = !existingCreatorBrandKeys.has(key);
                let currentStartDate = false;
                let currentEndDate = endDateET;

                const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
                  && d.typeOfDeal !== dealTypes.upfrontCPM
                  && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
                );
                if (firstDealInTimeframe) {
                  currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                    ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                      ? startDateET
                      : firstDealInTimeframe.previousDealLastPublishDate)
                    : firstDealInTimeframe.firstPublishDate;
                } else {
                  const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                    && d.typeOfDeal !== dealTypes.upfrontCPM
                    && d.firstPublishDate
                  );
                  if (latestDeal) {
                    if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                      currentStartDate = startDateET;
                    } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                      currentStartDate = latestDeal.firstPublishDate;
                    }
                  }
                }

                if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
                  const conversionsForDeal = brandCPAConversions.filter(c => c.revenue > 0
                    && moment.utc(c.datetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
                  if (conversionsForDeal?.length > 0) {
                    conversionsForDeal.forEach(c => {
                      if (c._id && !conversionIdsInTimeframe.has(String(c._id))) {
                        const label = moment.tz(moment.utc(c.datetime), 'America/New_York').format('YYYY-MM-DD');

                        if (!graphsData.conversionsInTimeframe[label]) {
                          graphsData.conversionsInTimeframe[label] = {
                            new: new Set(),
                            existing: new Set(),
                          }
                        }
                        graphsData.conversionsInTimeframe[label][`${isNew ? 'new' : 'existing'}`].add(String(c._id));

                        if (!graphsData.activeCreators[label]) {
                          graphsData.activeCreators[label] = {
                            new: new Set(),
                            existing: new Set(),
                          }
                        }
                        graphsData.activeCreators[label][`${isNew ? 'new' : 'existing'}`].add(key);

                        if (!graphsData.spend[label]) {
                          graphsData.spend[label] = {
                            new: 0,
                            existing: 0,
                          }
                        }
                        graphsData.spend[label][`${isNew ? 'new' : 'existing'}`] += Number(c.payout || 0);

                        conversionIdsInTimeframe.add(String(c._id));
                      }
                    });
                  }
                }
              }
            }
          });
        }
      }
    });

    Object.keys(allCPMConversionsToDate).forEach((currentCreatorId) => {
      if (allCPMConversionsToDate[currentCreatorId]) {
        Object.keys(allCPMConversionsToDate[currentCreatorId]).forEach((currentBrandId) => {
          if (allCPMConversionsToDate[currentCreatorId][currentBrandId]?.length > 0) {
            const key = `${currentCreatorId}-${currentBrandId}`;
            const isNew = !existingCreatorBrandKeys.has(key);
            let currentStartDate = false;
            let currentEndDate = toDateET;

            const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
              && d.typeOfDeal === dealTypes.upfrontCPM
              && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
            );
            if (firstDealInTimeframe) {
              currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                  ? startDateET
                  : firstDealInTimeframe.previousDealLastPublishDate)
                : firstDealInTimeframe.firstPublishDate;
            } else {
              const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                && d.typeOfDeal === dealTypes.upfrontCPM
                && d.firstPublishDate
              );
              if (latestDeal) {
                if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                  currentStartDate = startDateET;
                } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                  currentStartDate = latestDeal.firstPublishDate;
                }
              }
            }

            if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
              const conversionsForDeal = allCPMConversionsToDate[currentCreatorId][currentBrandId].filter(c => c.action === 'Paid'
                && moment.utc(c.activityDatetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
              if (conversionsForDeal?.length > 0) {
                conversionsForDeal.forEach(c => {
                  if (c._id && !conversionIdsToDate.has(String(c._id))) {
                    const label = moment.tz(moment.utc(c.activityDatetime), 'America/New_York').format('YYYY-MM-DD');

                    if (!graphsData.conversionsToDate[label]) {
                      graphsData.conversionsToDate[label] = {
                        new: new Set(),
                        existing: new Set(),
                      }
                    }
                    graphsData.conversionsToDate[label][`${isNew ? 'new' : 'existing'}`].add(String(c._id));

                    conversionIdsToDate.add(String(c._id));
                  }
                });
              }
            }
          }
        });
      }
    });

    if (aura?._id) {
      Object.keys(allAuraCPMConversionsToDate).forEach((currentCreatorTuneId) => {
        if (allAuraCPMConversionsToDate[currentCreatorTuneId]) {
          const currentCreator = creators.find(c => c?.affiliateDataObject?.tune_id && String(c.affiliateDataObject.tune_id) === String(currentCreatorTuneId));
          if (currentCreator?._id) {
            const brandOffers = Object.values(offers)
              .filter(offer => String(offer.advertiser?._id) === String(aura._id));
            const brandOfferTuneIds = brandOffers?.map(offer => offer.tuneId) || [];
            const brandCPAConversions = allAuraCPMConversionsToDate[currentCreatorTuneId]?.filter(c => (c.advertiser_id
                && Number(c.advertiser_id) && Number(c.advertiser_id) === Number(aura.tuneId))
              || (brandOfferTuneIds.length > 0 && Number(c.offer_id) && brandOfferTuneIds.includes(Number(c.offer_id))));

            if (brandCPAConversions?.length > 0) {
              const key = `${String(currentCreator._id)}-${String(aura._id)}`;
              const isNew = !existingCreatorBrandKeys.has(key);
              let currentStartDate = false;
              let currentEndDate = toDateET;

              const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
                && d.typeOfDeal === dealTypes.upfrontCPM
                && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
              );
              if (firstDealInTimeframe) {
                currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                  ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                    ? startDateET
                    : firstDealInTimeframe.previousDealLastPublishDate)
                  : firstDealInTimeframe.firstPublishDate;
              } else {
                const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                  && d.typeOfDeal === dealTypes.upfrontCPM
                  && d.firstPublishDate
                );
                if (latestDeal) {
                  if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = startDateET;
                  } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                    currentStartDate = latestDeal.firstPublishDate;
                  }
                }
              }

              if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
                const conversionsForDeal = brandCPAConversions.filter(c => c.revenue === 0
                  && moment.utc(c.datetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
                if (conversionsForDeal?.length > 0) {
                  conversionsForDeal.forEach(c => {
                    if (c._id && !conversionIdsToDate.has(String(c._id))) {
                      const label = moment.tz(moment.utc(c.datetime), 'America/New_York').format('YYYY-MM-DD');

                      if (!graphsData.conversionsToDate[label]) {
                        graphsData.conversionsToDate[label] = {
                          new: new Set(),
                          existing: new Set(),
                        }
                      }
                      graphsData.conversionsToDate[label][`${isNew ? 'new' : 'existing'}`].add(String(c._id));

                      conversionIdsToDate.add(String(c._id));
                    }
                  });
                }
              }
            }
          }
        }
      });
    }

    Object.keys(allConversionsToDate).forEach((currentCreatorTuneId) => {
      if (allConversionsToDate[currentCreatorTuneId]) {
        const currentCreator = creators.find(c => c?.affiliateDataObject?.tune_id && String(c.affiliateDataObject.tune_id) === String(currentCreatorTuneId));
        if (currentCreator?._id) {
          advertisers.filter(advertiser => advertiser.tuneId).forEach((currentBrand) => {
            if (currentBrand?._id) {
              const brandOffers = Object.values(offers)
                .filter(offer => String(offer.advertiser?._id) === String(currentBrand._id));
              const brandOfferTuneIds = brandOffers?.map(offer => offer.tuneId) || [];
              const brandCPAConversions = allConversionsToDate[currentCreatorTuneId]?.filter(c => (c.advertiser_id
                  && Number(c.advertiser_id) && Number(c.advertiser_id) === Number(currentBrand.tuneId))
                || (brandOfferTuneIds.length > 0 && Number(c.offer_id) && brandOfferTuneIds.includes(Number(c.offer_id))));

              if (brandCPAConversions?.length > 0) {
                const key = `${String(currentCreator._id)}-${String(currentBrand._id)}`;
                const isNew = !existingCreatorBrandKeys.has(key);
                let currentStartDate = false;
                let currentEndDate = toDateET;

                const firstDealInTimeframe = dealsData.find(d => d.creatorBrandKey === key
                  && d.typeOfDeal !== dealTypes.upfrontCPM
                  && (d.firstPublishDate && isMomentDateInSelectedTimeframe(d.firstPublishDate))
                );
                if (firstDealInTimeframe) {
                  currentStartDate = firstDealInTimeframe.previousDealLastPublishDate
                    ? (isMomentDateBeforeSelectedTimeframe(firstDealInTimeframe.previousDealLastPublishDate)
                      ? startDateET
                      : firstDealInTimeframe.previousDealLastPublishDate)
                    : firstDealInTimeframe.firstPublishDate;
                } else {
                  const latestDeal = dealsDataReversed.find(d => d.creatorBrandKey === key
                    && d.typeOfDeal !== dealTypes.upfrontCPM
                    && d.firstPublishDate
                  );
                  if (latestDeal) {
                    if (isMomentDateBeforeSelectedTimeframe(latestDeal.firstPublishDate)) {
                      currentStartDate = startDateET;
                    } else if (isMomentDateInSelectedTimeframe(latestDeal.firstPublishDate)) {
                      currentStartDate = latestDeal.firstPublishDate;
                    }
                  }
                }

                if (currentStartDate && currentEndDate && currentStartDate.isBefore(currentEndDate)) {
                  const conversionsForDeal = brandCPAConversions.filter(c => c.revenue > 0
                    && moment.utc(c.datetime).isBetween(currentStartDate, currentEndDate, undefined, '[]'));
                  if (conversionsForDeal?.length > 0) {
                    conversionsForDeal.forEach(c => {
                      if (c._id && !conversionIdsToDate.has(String(c._id))) {
                        const label = moment.utc(c.datetime).tz('America/New_York').format('YYYY-MM-DD');

                        if (!graphsData.conversionsToDate[label]) {
                          graphsData.conversionsToDate[label] = {
                            new: new Set(),
                            existing: new Set(),
                          }
                        }
                        graphsData.conversionsToDate[label][`${isNew ? 'new' : 'existing'}`].add(String(c._id));

                        conversionIdsToDate.add(String(c._id));
                      }
                    });
                  }
                }
              }
            }
          });
        }
      }
    });

    // console.log('graphsData: ', graphsData);

    return graphsData;
  }

  const statsInTimeframe = useMemo(() => {
    const defaultData = {
      newActiveCreators: new Set(),
      existingActiveCreators: new Set(),
      newCreatorsPublished: new Set(),
      existingCreatorsPublished: new Set(),
      newConversionsInTimeframe: new Set(),
      existingConversionsInTimeframe: new Set(),
      newSpend: 0,
      existingSpend: 0,
    }
    const sumAll = ({
      newActiveCreators = new Set(),
      existingActiveCreators = new Set(),
      newCreatorsPublished = new Set(),
      existingCreatorsPublished = new Set(),
      newConversionsInTimeframe = new Set(),
      existingConversionsInTimeframe = new Set(),
      newSpend = 0,
      existingSpend = 0,
      label,
    }, item) => {
      return {
        newActiveCreators: item?.newActiveCreators?.size > 0 ? new Set([...newActiveCreators, ...item.newActiveCreators]) : newActiveCreators,
        existingActiveCreators: item?.existingActiveCreators?.size > 0 ? new Set([...existingActiveCreators, ...item.existingActiveCreators]) : existingActiveCreators,
        newCreatorsPublished: item?.newCreatorsPublished?.size > 0 ? new Set([...newCreatorsPublished, ...item.newCreatorsPublished]) : newCreatorsPublished,
        existingCreatorsPublished: item?.existingCreatorsPublished?.size > 0 ? new Set([...existingCreatorsPublished, ...item.existingCreatorsPublished]) : existingCreatorsPublished,
        newConversionsInTimeframe: item?.newConversionsInTimeframe?.size > 0 ? new Set([...newConversionsInTimeframe, ...item.newConversionsInTimeframe]) : newConversionsInTimeframe,
        existingConversionsInTimeframe: item?.existingConversionsInTimeframe?.size > 0 ? new Set([...existingConversionsInTimeframe, ...item.existingConversionsInTimeframe]) : existingConversionsInTimeframe,
        newSpend: newSpend + item?.newSpend || 0,
        existingSpend: existingSpend + item?.existingSpend || 0,
        label: label || item?.label,
      };
    };
    const option = selectedDateOption.value;
    const startMoment = startDateET;
    const endMoment = endDateET;
    const chartData = {};
    const chartDataMonth = {};
    const chartDataWeek = {};
    const totalData = {};
    const days = endMoment.diff(startMoment, 'day');
    const months = endMoment.diff(startMoment, 'month');
    const weeks = endMoment.diff(startMoment, 'weeks') + 1;

    for (let i = 0; i <= days; i += 1) {
      const momentDate = i === 0 ? startMoment.clone() : startMoment.clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData[key] = {
        label: momentDate.format('YYYY-MM-DD'),
        ...defaultData,
      };
      totalData[key] = {
        label: momentDate.format('YYYY-MM-DD'),
        ...defaultData,
      };
    }
    for (let i = 0; i <= months; i += 1) {
      const momentDate = i === 0 ? startMoment.clone() : startMoment.clone().add(i, 'month');
      const key = momentDate.format('YYYY-MM');
      chartDataMonth[key] = {
        label: momentDate.format('M/YY'),
        ...defaultData,
      };
    }
    for (let i = 0; i <= weeks; i += 1) {
      const momentDate = i === 0 ? startMoment.clone().endOf('day') : startMoment.clone().add(i, 'week').startOf('isoWeek');
      const key = momentDate.format('YYYY-[W]WW');
      // console.log(key, moment(key, 'YYYY-[W]WW').startOf('isoWeek'), endMoment);
      if (momentDate > endMoment) break;
      const labelStart = moment(key, 'YYYY-[W]WW').startOf('isoWeek').format('MMM D, YYYY');
      const labelEnd = i === weeks - 1 ? endMoment.clone().format('MMM D, YYYY') : moment(key, 'YYYY-[W]WW').endOf('isoWeek').format('MMM D, YYYY');
      chartDataWeek[key] = {
        label: `${labelStart} - ${labelEnd}`,
        ...defaultData,
      };
    }


    Object.keys(chartData).forEach((label) => {
      const week = moment(label).format('YYYY-[W]WW');
      const byMonthKey = label.substring(0, 7);
      let newActiveCreators = new Set();
      let existingActiveCreators = new Set();
      let newCreatorsPublished = new Set();
      let existingCreatorsPublished = new Set();
      let newConversionsInTimeframe = new Set();
      let existingConversionsInTimeframe = new Set();
      let newSpend = 0;
      let existingSpend = 0;

      if (statsDataByDates?.activeCreators[label]) {
        newActiveCreators = statsDataByDates.activeCreators[label].new;
        existingActiveCreators = statsDataByDates.activeCreators[label].existing;
      }
      if (statsDataByDates?.creatorsPublished[label]) {
        newCreatorsPublished = statsDataByDates.creatorsPublished[label].new;
        existingCreatorsPublished = statsDataByDates.creatorsPublished[label].existing;
      }
      if (statsDataByDates?.conversionsInTimeframe[label]) {
        newConversionsInTimeframe = statsDataByDates.conversionsInTimeframe[label].new;
        existingConversionsInTimeframe = statsDataByDates.conversionsInTimeframe[label].existing;
      }
      if (statsDataByDates?.spend[label]) {
        newSpend = statsDataByDates.spend[label].new;
        existingSpend = statsDataByDates.spend[label].existing;
      }
      chartData[label] = sumAll({
        newActiveCreators,
        existingActiveCreators,
        newCreatorsPublished,
        existingCreatorsPublished,
        newConversionsInTimeframe,
        existingConversionsInTimeframe,
        newSpend,
        existingSpend,
      }, chartData[label]);
      chartDataMonth[byMonthKey] = sumAll({
        newActiveCreators,
        existingActiveCreators,
        newCreatorsPublished,
        existingCreatorsPublished,
        newConversionsInTimeframe,
        existingConversionsInTimeframe,
        newSpend,
        existingSpend,
        label: moment(label).format('M/YY'),
      }, chartDataMonth[byMonthKey]);
      chartDataWeek[week] = sumAll({
        newActiveCreators,
        existingActiveCreators,
        newCreatorsPublished,
        existingCreatorsPublished,
        newConversionsInTimeframe,
        existingConversionsInTimeframe,
        newSpend,
        existingSpend,
      }, chartDataWeek[week]);
    });

    if (option === 'total') {
      let beforeValue = {
        ...defaultData,
      };
      Object.keys(chartData).forEach((day) => {
        const valueForDays = chartData[day];
        totalData[day] = sumAll(beforeValue, valueForDays);
        beforeValue = { ...totalData[day] };
      });
    }
    // console.log('chartData:',Object.values(chartData));
    // console.log('chartDataWeek:',Object.values(chartDataWeek));
    // console.log('chartDataMonth:', Object.values(chartDataMonth));
    if (option === 'month') return Object.values(chartDataMonth).reduce((acc, cur) => {
      acc.push({
        label: cur.label,
        newActiveCreators: cur.newActiveCreators.size,
        existingActiveCreators: cur.existingActiveCreators.size,
        newCreatorsPublished: cur.newCreatorsPublished.size,
        existingCreatorsPublished: cur.existingCreatorsPublished.size,
        newConversionsInTimeframe: cur.newConversionsInTimeframe.size,
        existingConversionsInTimeframe: cur.existingConversionsInTimeframe.size,
        newSpend: cur.newSpend,
        existingSpend: cur.existingSpend,
      });
      return acc;
    }, []);
    if (option === 'week') return Object.values(chartDataWeek).reduce((acc, cur) => {
      acc.push({
        label: cur.label,
        newActiveCreators: cur.newActiveCreators.size,
        existingActiveCreators: cur.existingActiveCreators.size,
        newCreatorsPublished: cur.newCreatorsPublished.size,
        existingCreatorsPublished: cur.existingCreatorsPublished.size,
        newConversionsInTimeframe: cur.newConversionsInTimeframe.size,
        existingConversionsInTimeframe: cur.existingConversionsInTimeframe.size,
        newSpend: cur.newSpend,
        existingSpend: cur.existingSpend,
      });
      return acc;
    }, []);
    if (option === 'total') return Object.values(totalData).reduce((acc, cur) => {
      acc.push({
        label: cur.label,
        newActiveCreators: cur.newActiveCreators.size,
        existingActiveCreators: cur.existingActiveCreators.size,
        newCreatorsPublished: cur.newCreatorsPublished.size,
        existingCreatorsPublished: cur.existingCreatorsPublished.size,
        newConversionsInTimeframe: cur.newConversionsInTimeframe.size,
        existingConversionsInTimeframe: cur.existingConversionsInTimeframe.size,
        newSpend: cur.newSpend,
        existingSpend: cur.existingSpend,
      });
      return acc;
    }, []);
    return Object.values(chartData).reduce((acc, cur) => {
      acc.push({
        label: cur.label,
        newActiveCreators: cur.newActiveCreators.size,
        existingActiveCreators: cur.existingActiveCreators.size,
        newCreatorsPublished: cur.newCreatorsPublished.size,
        existingCreatorsPublished: cur.existingCreatorsPublished.size,
        newConversionsInTimeframe: cur.newConversionsInTimeframe.size,
        existingConversionsInTimeframe: cur.existingConversionsInTimeframe.size,
        newSpend: cur.newSpend,
        existingSpend: cur.existingSpend,
      });
      return acc;
    }, []);
  }, [selectedDateOption, statsDataByDates]);

  const statsToDate = useMemo(() => {
    const defaultData = {
      newConversionsToDate: new Set(),
      existingConversionsToDate: new Set(),
    }
    const sumAll = ({
      newConversionsToDate = new Set(),
      existingConversionsToDate = new Set(),
      label,
    }, item) => {
      return {
        newConversionsToDate: item?.newConversionsToDate?.size > 0 ? new Set ([...newConversionsToDate, ...item.newConversionsToDate]) : newConversionsToDate,
        existingConversionsToDate: item?.existingConversionsToDate?.size > 0 ? new Set([...existingConversionsToDate, ...item.existingConversionsToDate]) : existingConversionsToDate,
        label: label || item?.label,
      };
    };
    const option = selectedDateOption.value;
    const startMoment = startDateET;
    const endMoment = toDateET;
    const chartData = {};
    const chartDataMonth = {};
    const chartDataWeek = {};
    const totalData = {};
    const days = endMoment.diff(startMoment, 'day');
    const months = endMoment.diff(startMoment, 'month');
    const weeks = endMoment.diff(startMoment, 'weeks') + 1;

    for (let i = 0; i <= days; i += 1) {
      const momentDate = i === 0 ? startMoment.clone() : startMoment.clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData[key] = {
        label: momentDate.format('YYYY-MM-DD'),
        ...defaultData,
      };
      totalData[key] = {
        label: momentDate.format('YYYY-MM-DD'),
        ...defaultData,
      };
    }
    for (let i = 0; i <= months; i += 1) {
      const momentDate = i === 0 ? startMoment.clone() : startMoment.clone().add(i, 'month');
      const key = momentDate.format('YYYY-MM');
      chartDataMonth[key] = {
        label: momentDate.format('M/YY'),
        ...defaultData,
      };
    }
    for (let i = 0; i <= weeks; i += 1) {
      const momentDate = i === 0 ? startMoment.clone().endOf('day') : startMoment.clone().add(i, 'week').startOf('isoWeek');
      const key = momentDate.format('YYYY-[W]WW');
      // console.log(key, moment(key, 'YYYY-[W]WW').startOf('isoWeek'), endMoment);
      if (momentDate > endMoment) break;
      const labelStart = moment(key, 'YYYY-[W]WW').startOf('isoWeek').format('MMM D, YYYY');
      const labelEnd = i === weeks - 1 ? endMoment.clone().format('MMM D, YYYY') : moment(key, 'YYYY-[W]WW').endOf('isoWeek').format('MMM D, YYYY');
      chartDataWeek[key] = {
        label: `${labelStart} - ${labelEnd}`,
        ...defaultData,
      };
    }

    Object.keys(chartData).forEach((label) => {
      const week = moment(label).format('YYYY-[W]WW');
      const byMonthKey = label.substring(0, 7);
      let newConversionsToDate = new Set();
      let existingConversionsToDate = new Set();

      if (statsDataByDates?.conversionsToDate[label]) {
        newConversionsToDate = statsDataByDates.conversionsToDate[label].new;
        existingConversionsToDate = statsDataByDates.conversionsToDate[label].existing;
      }
      chartData[label] = sumAll({
        newConversionsToDate,
        existingConversionsToDate,
      }, chartData[label]);
      chartDataMonth[byMonthKey] = sumAll({
        newConversionsToDate,
        existingConversionsToDate,
        label: moment(label).format('M/YY'),
      }, chartDataMonth[byMonthKey]);
      chartDataWeek[week] = sumAll({
        newConversionsToDate,
        existingConversionsToDate,
      }, chartDataWeek[week]);
    });

    if (option === 'total') {
      let beforeValue = {
        ...defaultData,
      };
      Object.keys(chartData).forEach((day) => {
        const valueForDays = chartData[day];
        totalData[day] = sumAll(beforeValue, valueForDays);
        beforeValue = { ...totalData[day] };
      });
    }
    // console.log('chartData:',Object.values(chartData));
    // console.log('chartDataWeek:',Object.values(chartDataWeek));
    // console.log('chartDataMonth:', Object.values(chartDataMonth));
    if (option === 'month') return Object.values(chartDataMonth).reduce((acc, cur) => {
      acc.push({
        label: cur.label,
        newConversionsToDate: cur.newConversionsToDate.size,
        existingConversionsToDate: cur.existingConversionsToDate.size,
      });
      return acc;
    }, []);
    if (option === 'week') return Object.values(chartDataWeek).reduce((acc, cur) => {
      acc.push({
        label: cur.label,
        newConversionsToDate: cur.newConversionsToDate.size,
        existingConversionsToDate: cur.existingConversionsToDate.size,
      });
      return acc;
    }, []);
    if (option === 'total') return Object.values(totalData).reduce((acc, cur) => {
      acc.push({
        label: cur.label,
        newConversionsToDate: cur.newConversionsToDate.size,
        existingConversionsToDate: cur.existingConversionsToDate.size,
      });
      return acc;
    }, []);
    return Object.values(chartData).reduce((acc, cur) => {
      acc.push({
        label: cur.label,
        newConversionsToDate: cur.newConversionsToDate.size,
        existingConversionsToDate: cur.existingConversionsToDate.size,
      });
      return acc;
    }, []);
  }, [selectedDateOption, statsDataByDates]);

  const processData = ({
    dealsData,
    advertisers,
    creators,
    admins,
    offers,
    allCPMConversionsInTimeframe,
    allAuraCPMConversionsInTimeframe,
    allConversionsInTimeframe,
    allCPMConversionsToDate,
    allAuraCPMConversionsToDate,
    allConversionsToDate,
    settings
  }) => {
    if (dealsData && Array.isArray(dealsData)) {
      const baseProcessedDealsData = baseProcessDealsData(dealsData);

      const { mediaContentBeforeStartDate, mediaContentInTimeframe } = getSortedMediaContent(baseProcessedDealsData);
      // console.log('mediaContentBeforeStartDate: ', mediaContentBeforeStartDate);
      // console.log('mediaContentInTimeframe: ', mediaContentInTimeframe);
      // console.log('mediaContentToDate: ', mediaContentToDate);

      const existingCreatorBrandKeys = getCreatorBrandKeysSetFromMediaContents(
        mediaContentBeforeStartDate,
        advertisers,
        creators
      );
      // console.log('existingCreatorBrandKeys: ', [...existingCreatorBrandKeys]);

      const processedDealsDataForNeighboringPublishDates = processDealsDataForNeighboringPublishDates(baseProcessedDealsData);

      calculateTimeframeSummary(
        processedDealsDataForNeighboringPublishDates,
        existingCreatorBrandKeys,
        advertisers,
        creators,
        offers,
        allCPMConversionsInTimeframe,
        allAuraCPMConversionsInTimeframe,
        allConversionsInTimeframe,
        allCPMConversionsToDate,
        allAuraCPMConversionsToDate,
        allConversionsToDate,
      );

      setStatsDataByDates(getStatsDataByDates({
        dealsData: processedDealsDataForNeighboringPublishDates,
        creators,
        advertisers,
        offers,
        existingCreatorBrandKeys,
        mediaContentInTimeframe,
        allCPMConversionsInTimeframe,
        allAuraCPMConversionsInTimeframe,
        allConversionsInTimeframe,
        allCPMConversionsToDate,
        allAuraCPMConversionsToDate,
        allConversionsToDate,
      }));

      const processedDealsDataWithConversions = processDealsDataWithConversions({
        dealsData: processedDealsDataForNeighboringPublishDates,
        existingCreatorBrandKeys,
        admins,
        allCPMConversionsInTimeframe,
        allAuraCPMConversionsInTimeframe,
        allConversionsInTimeframe,
        allCPMConversionsToDate,
        allAuraCPMConversionsToDate,
        allConversionsToDate,
        settings,
      });
      // console.log('processedDealsDataWithConversions: ', processedDealsDataWithConversions);
      setByPublishItems(processedDealsDataWithConversions);

      calculateWinnersSummary(processedDealsDataWithConversions);

      const processedDealsDataForByCreatorView = processDealsDataForByCreatorView(processedDealsDataWithConversions);
      // console.log('processedDealsDataForByCreatorView: ', processedDealsDataForByCreatorView);
      setByCreatorItems(processedDealsDataForByCreatorView);
    }
  }

  const fetchReport = async () => {
    setLoading(true);
    setShowPredicted(false);
    setByPublishItems([]);
    setByCreatorItems([]);
    setTimeframeSummary([]);
    setWinnersSummary([]);
    setStatsDataByDates(null);
    clear();
    try {
      const resp = await axios.post('/api/portal/reports/post-test-to-win-report-base-data', {
        ...(selectedAdvertisers?.length > 0 && { brandIds: selectedAdvertisers.map(option => option.value) }),
        ...(selectedEmployee?.length > 0 && { affiliateManagerIds: selectedEmployee.map(option => option.value) }),
        ...(selectedCreator?.length > 0 && { influencerIds: selectedCreator.map(option => option.value) }),
        ...(selectedDealType?.length > 0 && { dealTypes: selectedDealType.map(option => option.value) }),
        startDate,
        endDate,
        identity,
      });
      console.log(resp.data);

      if (resp.data.success) {
        // setDealItems(resp.data.deals);
        // setByCreatorItems(resp.data.creators);
        // setTimeframeSummary(resp.data.timeframeSummary);
        // setTimeframeWinningSummary(resp.data.winnersSummary);
        // setShowPredicted(resp.data.showPredicted)
        processData(resp.data);
        setShowPredicted(resp.data?.showPredicted);
        setFetched(true);
        setLoading(false);
        setErrors([]);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
      setFetched(false);
      setLoading(false);
      setErrors([]);
    }
    return true;
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'Test To Win Report', path: false },
            ]}
          />
        </Col>
      </Row>
      {!loading && fetched && errors.length > 0 ? (
        <Errors errors={errors} startDate={startDate} endDate={endDate} />
      ) : null }
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <Row>
                  <Col>
                    <h3>Test To Win Report</h3>
                  </Col>
                  <Col lg="auto">
                    <AccessComponent showWhenTeam={showWhenTeamIs}>
                      <div className="mt-0">
                        <Button
                          color="primary"
                          onClick={() => window.open('/reports/settings', '_blank').focus()}
                          className="btn-sm mb-0"
                          disabled={!!loading}
                          style={{width: '125px'}}
                        >
                          Settings
                        </Button>
                      </div>
                    </AccessComponent>
                  </Col>
                </Row>
              </CardTitle>
              <hr />
              {minDate ? (
                <Row className="mt-3" >
                  <Col>
                    <Label className="bold-text">Date Range</Label>
                    <Filter
                      startDateInitial={moment(startDate).toISOString()}
                      endDateInitial={moment(endDate).endOf('day').toISOString()}
                      selectedButtonInitial="current_month"
                      setValue={(param, value) => {
                        if (param === 'startDate') {
                          setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                        }
                        if (param === 'endDate') {
                          setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                        }
                      }}
                      usedForConversions
                      isLoading={!!loading}
                      options={[
                        { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
                        { value: 'last_month', label: 'Last Month' },
                        { value: 'last_90_days', label: 'Last 90 Days' },
                        { value: 'year_to_date', label: 'Year to Date' },
                        { value: 'all_time', label: 'All Time' },
                        {
                          value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
                        },
                      ]}
                      minDate={moment().subtract(120, 'months').toDate()}
                      selectDateRangeFunction={(range) => {
                        let dateFrom;
                        let dateTo;
                        let showCustom = false;
                        switch (range) {
                          case 'current_month':
                            showCustom = false;
                            dateFrom = moment().startOf('month').toDate();
                            dateTo = (moment().endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().endOf('day')).toDate();
                            break;
                          case 'last_month':
                            showCustom = false;
                            dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
                            dateTo = moment().subtract(1, 'month').endOf('month').toDate();
                            break;
                          case 'last_90_days':
                            showCustom = false;
                            dateFrom = moment().subtract(90, 'day').startOf('day').toDate();
                            dateTo = moment().endOf('day').toDate();
                            break;
                          case 'year_to_date':
                            showCustom = false;
                            dateFrom = moment().startOf('year').toDate();
                            dateTo = (moment().endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().endOf('day')).toDate();
                            break;
                          case 'all_time':
                            showCustom = false;
                            dateFrom = moment().subtract(120, 'months').toDate();
                            dateTo = (moment().endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().endOf('day')).toDate();
                            break;
                          case 'custom':
                            showCustom = true;
                            dateFrom = moment().startOf('Year').toDate();
                            dateTo = moment().endOf('day').toDate();
                            break;
                          default:
                            dateFrom = moment().startOf('month').toDate();
                            dateTo = moment().endOf('day').toDate();
                            break;
                        }
                        return { dateFrom, dateTo, showCustom };
                      }
                    }
                    />
                  </Col>
                  <Col lg="auto">
                    <div style={{ paddingTop: '30px' }}>
                      <Button
                        color="primary"
                        onClick={async () => {
                          await fetchReport();
                        }}
                        className="btn-sm"
                        disabled={!!loading}
                      >
                        Run Report
                      </Button>
                    </div>
                  </Col>
                </Row>) : null
              }
              {minDate ? (
                <Row className="mt-3">
                  <Col>
                    <Actions
                      selectedEmployee={selectedEmployee}
                      setSelectedEmployee={setSelectedEmployee}
                      selectedCreator={selectedCreator}
                      setSelectedCreator={setSelectedCreator}
                      selectedAdvertiser={selectedAdvertisers}
                      setSelectedAdvertiser={setSelectedAdvertisers}
                      setSelectedDealType={setSelectedDealType}
                      selectedDealType={selectedDealType}
                    />
                  </Col>
                </Row>) : null
              }
              <Row>
                <Col>
                  <LoadingReport loading={loading} />
                </Col>
              </Row>
              {/* loading ? (
                <Row className="mt-3">
                  <Col className="text-center">
                    <Spinner size="lg" color="primary" />
                  </Col>
                </Row>) : null
              */}
              {!loading && fetched ? (
                <>
                  <Row className="mt-3">
                    {timeframeSummary?.length > 0 && (
                      <Col md={6}>
                        <h4>New vs Existing Summary</h4>
                        <Row className="mt-2">
                          <Col md={4}>
                            <Label className="bold-text">Statistic</Label>
                          </Col>
                          <Col md={8}>
                            <Row>
                              <Col md={4}>
                                <Label className="bold-text">New</Label>
                              </Col>
                              <Col md={4}>
                                <Label className="bold-text">Existing</Label>
                              </Col>
                              <Col md={4}>
                                <Label className="bold-text">Total</Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr style={{ marginTop: '0' }}/>
                        {timeframeSummary.map((row) => (
                          <Row>
                            <Col md={4}>
                              <Label className="bold-text">{row.stat}</Label>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <Col md={4}>
                                  {row.new}
                                </Col>
                                <Col md={4}>
                                  {row.existing}
                                </Col>
                                <Col md={4}>
                                  {row.total}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ))}
                        <hr style={{ marginTop: '0' }}/>
                      </Col>
                    )}
                    {statsDataByDates && (statsInTimeframe || statsToDate) && (
                      <Col md={6}>
                        <Row key="1" className="mt-1">
                          <Col md={6}>
                            <div
                              className="form-group"
                              style={{ width: '230px' }}
                            >
                              <Select
                                placeholder="Select Data Property to Chart..."
                                value={selectedDataOption}
                                options={dataOptions}
                                onChange={setSelectedDataOption}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div
                              className="form-group"
                              style={{ width: '150px' }}
                            >
                              <Select
                                placeholder="Select Date Option..."
                                value={selectedDateOption}
                                options={dateOptions}
                                onChange={setSelectedDateOption}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row key="2">
                          <Col md={12}>
                            <ChartComponent
                              chartData={selectedDataOption.value === 'ConversionsToDate' ? statsToDate : statsInTimeframe}
                              chartType={selectedDataOption.value}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {winnersSummary?.length > 0 && (
                    <Row className="mt-3">
                      <Col md={12}>
                        <h4>Winner Summaries</h4>
                        {showPredicted ? (
                          <>
                            <Row>
                              <Col md={3}/>
                              <Col md={3}>
                                <div style={{
                                  width: '100%',
                                  textAlign: 'center '
                                }}>
                                  <Label className="bold-text">Using Revenue Conversions In Timeframe</Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div style={{
                                  width: '100%',
                                  textAlign: 'center '
                                }}>
                                  <Label className="bold-text">Using Revenue Conversions to Date</Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div style={{
                                  width: '100%',
                                  textAlign: 'center '
                                }}>
                                  <Label className="bold-text">Predicted to Win</Label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Label className="bold-text">Statistic</Label>
                              </Col>
                              <Col md={3}>
                                <Row>
                                  <Col md={6}>
                                    <Label className="bold-text">New</Label>
                                  </Col>
                                  <Col md={6}>
                                    <Label className="bold-text">Existing</Label>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={3}>
                                <Row>
                                  <Col md={6}>
                                    <Label className="bold-text">New</Label>
                                  </Col>
                                  <Col md={6}>
                                    <Label className="bold-text">Existing</Label>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={3}>
                                <Row>
                                  <Col md={6}>
                                    <Label className="bold-text">New</Label>
                                  </Col>
                                  <Col md={6}>
                                    <Label className="bold-text">Existing</Label>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <hr style={{ marginTop: '0' }}/>
                            {winnersSummary.map((row) => (
                              <Row>
                                <Col md={3}>
                                  <Label className="bold-text">{row.stat}</Label>
                                </Col>
                                <Col md={3}>
                                  <Row>
                                    <Col md={6}>
                                      {row.newInTimeframe}
                                    </Col>
                                    <Col md={6}>
                                      {row.existingInTimeframe}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={3}>
                                  <Row>
                                    <Col md={6}>
                                      {row.newToDate}
                                    </Col>
                                    <Col md={6}>
                                      {row.existingToDate}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={3}>
                                  <Row>
                                    <Col md={6}>
                                      {row.newPredicted}
                                    </Col>
                                    <Col md={6}>
                                      {row.existingPredicted}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                            <hr style={{ marginTop: '0' }}/>
                          </>
                        ) : (
                          <Row>
                          <Col md={9}>
                              <Row>
                                <Col md={4}/>
                                <Col md={4}>
                                  <div style={{
                                    width: '100%',
                                    textAlign: 'center '
                                  }}>
                                    <Label className="bold-text">Using Revenue Conversions In Timeframe</Label>
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div style={{
                                    width: '100%',
                                    textAlign: 'center '
                                  }}>
                                    <Label className="bold-text">Using Revenue Conversions to Date</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <Label className="bold-text">Statistic</Label>
                                </Col>
                                <Col md={4}>
                                  <Row>
                                    <Col md={6}>
                                      <Label className="bold-text">New</Label>
                                    </Col>
                                    <Col md={6}>
                                      <Label className="bold-text">Existing</Label>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={4}>
                                  <Row>
                                    <Col md={6}>
                                      <Label className="bold-text">New</Label>
                                    </Col>
                                    <Col md={6}>
                                      <Label className="bold-text">Existing</Label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <hr style={{ marginTop: '0' }}/>
                              {winnersSummary.map((row) => (
                                <Row>
                                  <Col md={4}>
                                    <Label className="bold-text">{row.stat}</Label>
                                  </Col>
                                  <Col md={4}>
                                    <Row>
                                      <Col md={6}>
                                        {row.newInTimeframe}
                                      </Col>
                                      <Col md={6}>
                                        {row.existingInTimeframe}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={4}>
                                    <Row>
                                      <Col md={6}>
                                        {row.newToDate}
                                      </Col>
                                      <Col md={6}>
                                        {row.existingToDate}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                              <hr style={{ marginTop: '0' }}/>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-3">
                    <Col className="tabs">
                      <h4>Creator Details</h4>
                      <Nav className="mt-2" tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === 'byPublish' })}
                            onClick={() => {
                              setActiveTab('byPublish');
                            }}
                          >
                            By Publish
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === 'creators' })}
                            onClick={() => {
                              setActiveTab('creators');
                            }}
                          >
                            By Creator
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="byPublish">
                          <GenerateByPublishTable
                            items={byPublishItems}
                          />
                        </TabPane>
                        <TabPane tabId="creators">
                          <GenerateCreatorsTable
                            items={byCreatorItems}
                          />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </>) : null
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
TestToWinReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(TestToWinReport);
