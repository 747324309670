import FullName from './FullName';
import Score from '../../../../../../shared/components/table/cell/Score';
import SelectResource from '../../../../../../shared/components/table/cell/SelectResource';
import Description from '../../../../../../shared/components/table/cell/Description';
import { yesNoOptions } from '../../../../../../shared/helpers/Form';
import {
  num,
  int,
  currency,
  percentage,
  date,
} from '../../../../../../shared/helpers/reports/Format';

const fill = (propertyName, value, record) => {
  const res = {
    [propertyName]: value,
    [`${propertyName}Datetime`]: new Date(),
  };
  if (!record.params['metadata._id']) {
    res.advertiser = record.params.advertiser;
    res.influencer = record.id;
  }
  return res;
};

const addMetadataUrl = '/api/portal/reports/post-save-pilot-metadata';

export default {
  list: {
    fullName: FullName,
    totalDeals: int,
    dealCost: currency,
    revenueConversions: int,
    clicks: int,
    views: int,
    comments: int,
    grossRevenue: currency,
    grossProfit: currency,
    CPM: currency,
    RPM: currency,
    clickComRatio: percentage,
    EPC: currency,
    CPC: currency,
    clickThroughRate: percentage,
    conversionRate: percentage,
    firstPublishDate: date,
    daysSinceFirstPublish: int,
    lastPublishDate: date,
    daysSinceLastPublish: int,
    qualityScore: ({
      resource,
      record,
      property,
    }) => record.isTotal ? num({ record, property }) : (
      <Score
        resource={{ id: 'PilotMetadata' }}
        record={record}
        property={property}
        label="Quality Score Rubric"
        options={[
          {
            label: 'Relevant personal b-roll',
            description: 'Reason: adds originality to the integration and shows the audience they use the product.',
            score: 1,
          },
          {
            label: 'Emphasized benefits',
            description: (
              <>
                <div>Clarity (1pt)</div>
                <div>Relevance (1pt)</div>
                <div>Reason: adds clarification to the why and how the audience can benefit from the product.</div>
              </>
            ),
            score: 2,
          },
          {
            label: 'Pinned comment with link',
            description: 'Reason: adds a second place for the link to get clicked, increasing CTR',
            score: 1,
          },
          {
            label: 'Timing in video',
            description: 'Reason: huge factor for how many viewers will actually see the ad.',
            score: 2,
          },
          {
            label: 'Content/audience alignment',
            description: 'Reason - Product needs to have some use case to the audience.',
            score: 2,
          },
          {
            label: 'Strong CTA',
            description: 'Reason - really important for reinforcing everything said in the ad and convincing viewers to click.',
            score: 2,
          },
        ]}
        primaryKey="metadata._id"
        fill={fill}
        addUrl={addMetadataUrl}
      />
    ),
    pinnedComment: ({
      resource,
      record,
      property,
    }) => !record.isTotal && (
      <SelectResource
        resource="PilotMetadata"
        record={record}
        property={property.name}
        options={yesNoOptions}
        isClearable={false}
        primaryKey="metadata._id"
        fill={fill}
        addUrl={addMetadataUrl}
      />
    ),
    notes: ({
      resource,
      record,
      property,
    }) => !record.isTotal && (
      <Description
        resource={{ id: 'PilotMetadata' }}
        record={record}
        property={property}
        primaryKey="metadata._id"
        fill={fill}
        addUrl={addMetadataUrl}
      />
    ),
  },
};
