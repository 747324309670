import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import withNotice from '../../../../../containers/App/store/with-notice';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import AdminTable from './AdminTable';
import EditVanityURLModal from './EditVanityURLModal';
import QrCodeComponent from './QrCodeComponent';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboardLeft';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import OfferStatusUpdater from './OfferStatusUpdater';
import {
  getVanityURLs,
} from '../../../../../redux/reducers/admin/InfluencerProfileReducer';

const ReRunIcon = ({ is200Status, vanityUrlId, influencerId, dispatch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const checkUrlStatus = async () => {
    setIsLoading(true);
    try {
      const result = await axios.post('/api/portal/redirects/check-result-url-status', { vanityUrlId });
      if (result.data.success) {
        setIsLoading(false);
        if(result.data.result !== is200Status) {
          dispatch(getVanityURLs(influencerId));
        }
      }
    } catch (error) {
      console.error('Error checking URL status:', error);
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  };

  return (
    <div>
      <Tooltip title="Re-check URL Status">
        <FontAwesomeIcon
          icon={faRedo}
          spin={isLoading}
          style={{ marginRight: '5px', cursor: 'pointer' }}
          onClick={checkUrlStatus}
        />
      </Tooltip>
      {!is200Status && <span className='text-danger'>No</span>}
      {is200Status && <span style={{ color: 'green' }}>Yes</span>}
    </div>
  )};

const WarningIcon = () => (
  <Tooltip title="A URL with the same path and the same Redirect Domain exists! This URL will not work as expected.">
    <span role="img" aria-label="warning" style={
      { fontSize: '1.5rem', color: 'orange', paddingRight: '5px' }
    }>
    ⚠️
  </span>
  </Tooltip>
);

const VanityURLsProfile = ({
  vanityURLs, loading, influencer, addNotice, dispatch,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedVanityURL, setSelectedVanityURL] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchPropertyOptions] = useState([
    { value: 'url', label: 'URL Path' },
    { value: 'channel', label: 'Channel Name' },
  ]);
  const [hs_offers, setHs_offers] = useState([]);
  const [searchPropertySelected, setSearchPropertySelected] = useState({ value: 'url', label: 'URL Path' });
  // eslint-disable-next-line no-unused-vars
  const [dataToShow, setDataToShow] = useState([]);
  const offerOptions = [];
  vanityURLs?.map((vanityURL) => {
    if (vanityURL.params.offer && vanityURL.params.offer._id && vanityURL.params.offer.name) {
      if (offerOptions.length > 0) {
        const found = offerOptions.find(option => option.value === vanityURL.params.offer._id);
        if (!found) offerOptions.push({ value: vanityURL.params.offer._id, label: vanityURL.params.offer.name });
      } else offerOptions.push({ value: vanityURL.params.offer._id, label: vanityURL.params.offer.name });
    }
    return false;
  });
  const [selectedOfferOptions, setSelectedOfferOptions] = useState([]);
  const [version, setVersion] = useState('0');

  useEffect(async () => {
    if (influencer?.params?.affiliateData?.tune_id) {
      const offerIds = vanityURLs.filter(v => v.params.offer?.tuneId).map(vanityURL => vanityURL.params.offer.tuneId);
      const uniqueOfferIdsSet = new Set(offerIds);
  
      if (uniqueOfferIdsSet.size > 0) {
        const hs_offers = await axios.post('/api/portal/hs-offers/byIds', {
          offerIds: Array.from(uniqueOfferIdsSet),
          affiliateId: influencer.params.affiliateData.tune_id
        });
        
        if(hs_offers && hs_offers.data && hs_offers.data.offers) {
          setHs_offers(hs_offers.data.offers);
        }
      }
    }
    
  }, [vanityURLs, version]);

  useEffect(async () => {
    let filteredArray = [];
    if (selectedOfferOptions && selectedOfferOptions.length > 0) {
      selectedOfferOptions.forEach((option) => {
        if (typeof option?.value !== 'undefined') {
          filteredArray.push(...vanityURLs.filter(vanityURL => option.value === vanityURL?.params.offer._id));
        }
      });
    } else filteredArray = [...vanityURLs];
    if (searchValue) {
      if (searchPropertySelected.value === 'url') {
        filteredArray = filteredArray.filter((vanityURL) => {
          if (vanityURL?.params?.type === 'betterhelp' && typeof vanityURL.params.source !== 'undefined') {
            return vanityURL.params.source.toLowerCase().includes(searchValue.toLowerCase());
          }
          if (typeof vanityURL?.params?.affSub !== 'undefined') {
            return vanityURL.params.affSub.toLowerCase().includes(searchValue.toLowerCase());
          }
          return false;
        });
      } else if (searchPropertySelected.value === 'channel') {
        filteredArray = filteredArray.filter((vanityURL) => {
          if (typeof vanityURL?.params?.affSub4 !== 'undefined') return decodeURIComponent(vanityURL.params.affSub4).toLowerCase().includes(searchValue.toLowerCase());
          return false;
        });
      }
    }
    setDataToShow(filteredArray);
    const newVersion = Number(version) + 1;
    setVersion(newVersion.toString());
  }, [vanityURLs, selectedOfferOptions, searchValue, searchPropertySelected]);

  const copyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log(`Copying text command was ${msg}.`);
      addNotice({
        message: `URL "${text}" copied to clipboard`,
        type: 'success',
      });
    } catch (err) {
      console.log('Oops, unable to copy');
      addNotice({
        message: 'Copying URL failed',
        type: 'error',
      });
    }

    document.body.removeChild(textArea);
  };

  const copyResultURLToClipboard = (vanityURL) => {
    if (vanityURL && vanityURL.params && vanityURL.params.resultUrl) {
      copyTextToClipboard(vanityURL.params.resultUrl);
    } else {
      addNotice({
        message: 'There is a problem with this Vanity URL, contact the Engineering Department',
        type: 'error',
      });
    }
  };

  const getVanityUrlLink = (domain, localPath, inCollision = false) => {
    const url = `${domain}/${localPath}`;
    return (
      <div style={{ minWidth: '220px' }}>
        {inCollision && (
              <WarningIcon />
        )}
        <CopyToClipboard value={url ? `https://${url}` : ''}>
          <strong>
            <a href={`https://${url}`} target="_blank" rel="noreferrer noopener" >
              {url}
            </a>
          </strong>
        </CopyToClipboard>
      </div>
    );
  };

  const columnTransform = {
    offer: {
      title: 'Offer',
      valueFunc: (vanityURL) => {
        if (vanityURL.params.offer && vanityURL.params.offer.name) {
          return (
            <div style={{ maxWidth: '130px' }}>
              <strong>{vanityURL.params.offer.name}</strong>
            </div>
          );
        }
        return '';
      },
      sort: (vanityURL) => {
        if (vanityURL.params.offer && vanityURL.params.offer.name) {
          return vanityURL.params.offer.name;
        }
        return '';
      },
    },
    offerStatus: {
      title: 'Offer Status',
      sort: (vanityURL) => {
        const offerId = vanityURL.params.offer?.tuneId;
        const currentStatus = hs_offers[offerId] || 'Not Approved';
        return currentStatus;
      },
      valueFunc: (vanityURL) => {
        const offerId = vanityURL.params.offer?.tuneId;
        const currentStatus = hs_offers[offerId] || 'Not Approved';
        const affiliateId = influencer.params.affiliateData?.tune_id;

        if(!affiliateId) {
          return <strong>{currentStatus}</strong>;
        }
    
        return (
          <OfferStatusUpdater
            offerId={offerId}
            affiliateId={affiliateId}
            currentStatus={currentStatus}
            refreshData={() => {
              const newVersion = Number(version) + 1;
              setVersion(newVersion.toString());
            }}
          />
        );
      },
    },
    is200Status: {
      title: 'URL is Live?',
      sort: (vanityURL) => {
        return vanityURL.params.is200Status;
      },
      valueFunc: (vanityURL) => {
        return <ReRunIcon
          is200Status={vanityURL.params.is200Status}
          vanityUrlId={vanityURL.params._id}
          influencerId={influencer.params._id}
          dispatch={dispatch}
        />;
      },
    },
    // redirectDomain: {
    //   title: 'Domain',
    //   valueFunc: (vanityURL) => {
    //     if (vanityURL.params.redirectDomain && vanityURL.params.redirectDomain.domain) {
    //       return (<div style={{ maxWidth: '150px' }}><strong>{vanityURL.params.redirectDomain.domain}</strong></div>);
    //     }
    //     return '';
    //   },
    //   sort: (vanityURL) => {
    //     if (vanityURL.params.redirectDomain && vanityURL.params.redirectDomain.domain) {
    //       return vanityURL.params.redirectDomain.domain;
    //     }
    //     return '';
    //   },
    // },
    // affSub: {
    //   title: 'URL Path',
    //   valueFunc: (vanityURL) => {
    //     if (vanityURL.params.type === 'betterhelp') {
    //       const value = vanityURL.params.source || vanityURL.params.localPath;
    //       return (<strong>{value}</strong>);
    //     }
    //     const value = vanityURL.params.affSub || vanityURL.params.localPath;
    //     return (<div style={{ maxWidth: '150px' }}><strong>{value}</strong></div>);
    //   },
    //   sort: (vanityURL) => {
    //     if (vanityURL.params.type === 'betterhelp') {
    //       return vanityURL.params.source || vanityURL.params.localPath;
    //     }
    //     return vanityURL.params.affSub || vanityURL.params.localPath;
    //   },
    // },
    affSub4: {
      title: 'Channel Name',
      valueFunc: vanityURL => (
        <div style={{ maxWidth: '130px' }}>
          <strong>{vanityURL.params.affSub4 ? decodeURIComponent(vanityURL.params.affSub4) : ''}</strong>
        </div>
      ),
      sort: vanityURL => vanityURL.params.affSub4,
    },
    urlId: {
      title: 'Landing Page',
      valueFunc: vanityURL => (<div style={{ maxWidth: '150px' }}><strong>{vanityURL.params.landingPage}</strong></div>),
      sort: vanityURL => vanityURL.params.landingPage,
    },
    cellVanityURL: {
      title: 'Vanity URL',
      valueFunc: (vanityURL) => {
        if (vanityURL.params.redirectDomain && vanityURL.params.redirectDomain.domain && vanityURL.params.localPath) {
          const localPath = vanityURL.params.localPath;
          return getVanityUrlLink(vanityURL.params.redirectDomain.domain, localPath, vanityURL.params.inCollision);
        }
        return '';
      },
      sort: (vanityURL) => {
        if (vanityURL.params.redirectDomain && vanityURL.params.redirectDomain.domain && vanityURL.params.localPath) {
          const localPath = vanityURL.params.localPath;
          return `${vanityURL.params.redirectDomain.domain}/${localPath}`;
        }
        return '';
      },
    },
    actions: {
      title: ' ',
      valueFunc: vanityURL => (
        <div className="text-left" style={{ minWidth: '240px' }}>
          <Button
            color="primary"
            className="radius-button"
            disabled={!!loading}
            onClick={() => copyResultURLToClipboard(vanityURL)}
            style={{
                marginBottom: '0',
                marginRight: '3px',
                paddingLeft: '15px',
                paddingRight: '15px',
              }}
          >
            <i className="fas fa-copy" />
            Redirect URL
          </Button>
          {vanityURL.params.resultUrl && (
          <QrCodeComponent
            url={vanityURL.params.resultUrl}
            dealname={vanityURL?.params?.offer?.name || ''}
          />
            )}
          {vanityURL.params.resultUrl && vanityURL.params.isLegacy === false && (
              ' '
            )}
          {vanityURL.params.isLegacy === false ? (
            <Tooltip title="Edit Vanity URL">
              <button
                className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall "
                onClick={() => {
                    setSelectedVanityURL(vanityURL);
                    setEditModalOpen(true);
                  }}
              >
                <i className="fa fa-edit" />
              </button>
            </Tooltip>
            ) : (
              <Tooltip title="This URL was migrated from our old system and lacks details to edit. Contact Engineering for help.">
                <button
                  className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall "
                  onClick={() => {}}
                  style={{ marginLeft: '3px' }}
                >
                  <i className="fa fa-exclamation" />
                </button>
              </Tooltip>
            )}
        </div>
      ),
      sort: () => 1,
    },
  };

  const selectItem = (value, type) => {
    switch (type) {
      case 'offer':
        setSelectedOfferOptions(value);
        break;
      default:
        return false;
    }
    return true;
  };

  const changeSearchValue = (event) => {
    setSearchValue(event?.target?.value);
    return true;
  };

  const selectSearchProperty = (option) => {
    setSearchPropertySelected(option);
    return true;
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
    control: base => ({
      ...base,
      height: '38px',
      minHeight: '38px',
    }),
  };

  const addByttonStyle = vanityURLs.length > 0 ? { marginBottom: '0' } : {};
  const tableContainerStyle = vanityURLs.length > 0 ? { padding: '0' } : {};

  return (
    <div key={`vanityURLs-${vanityURLs && vanityURLs.length > 0 ? vanityURLs[0].params._id : 'loading'}`}>
      <h2 className="section-title mt-0 mb-4">Vanity URLs</h2>
      <Card id="section-vanity-urls">
        <CardBody>
          { (influencer && influencer.params && influencer.params.affiliateData && influencer.params.affiliateData.account_manager_id)
            ? (
              <Row>
                <Col />
                <Col xs="auto">
                  <Button
                    color="primary"
                    id="new-vanity-url-btn"
                    className="radius-button"
                    disabled={!!loading || influencer?.params?.inBlackList}
                    onClick={() => {
                      setSelectedVanityURL(null);
                      setEditModalOpen(true);
                    }}
                    style={addByttonStyle}
                  >
                    <i className="fas fa-plus-circle" />
                    New Vanity URL
                  </Button>
                </Col>
              </Row>
            )
            : (
              <div className="pb-3" >
                <Alert color="danger" bordered icon>
                  <p className="py-2">
                    This Creator is missing some affiliate data. Please confirm the HasOffers Partner and User were created and the Partner has an Account Manager assigned.
                  </p>
                </Alert>
              </div>
            )
          }
          {!loading && vanityURLs.length > 0 && (
            <Row>
              <Col xs="4">
                <FormGroup>
                  <Label className="bold-text">
                    Offer
                  </Label>
                  <Select
                    isMulti
                    name="offerSelect"
                    isDisabled={loading}
                    isLoading={loading}
                    isClearable
                    value={selectedOfferOptions}
                    options={offerOptions}
                    onChange={value => selectItem(value, 'offer')}
                    placeholder="Select Offer..."
                    styles={colourStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs="4" />
              <Col
                xs="2"
                style={{ paddingTop: '30px' }}
              >
                <Input
                  placeholder="Search"
                  type="search"
                  onChange={changeSearchValue}
                  value={searchValue}
                  style={{
                    minHeight: '38px',
                  }}
                  bsSize="sm"
                />
              </Col>
              <Col
                xs="2"
                style={{ paddingTop: '30px' }}
              >
                <Select
                  name="searchPropertySelect"
                  onChange={selectSearchProperty}
                  value={searchPropertySelected}
                  options={searchPropertyOptions}
                  styles={colourStyles}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col
              style={tableContainerStyle}
            >
              {loading ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
              {!loading && vanityURLs.length > 0
                ? (
                  <AdminTable>
                    <MatTableForRedux
                      dataArray={dataToShow}
                      selectable={false}
                      columns={columnTransform}
                      version={version}
                      defaultOrder={{
                        order: 'desc',
                        orderBy: 'offer',
                      }}
                      rowsPerPage={10}
                    />
                  </AdminTable>
                )
                : !loading
                && !(selectedOfferOptions.length > 0 || searchValue)
                && (
                  <Alert color="warning" bordered icon >
                    <p className="py-2">
                      The Creator currently has no Vanity URLs. Use the <strong>New Vanity URL</strong> button to add a Vanity URL.
                    </p>
                  </Alert>
                )
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
      <EditVanityURLModal
        isOpen={editModalOpen}
        influencer={influencer}
        vanityURL={selectedVanityURL}
        toggleModal={() => {
          setEditModalOpen(false);
          setSelectedVanityURL(null);
        }}
      />
    </div>
  );
};
VanityURLsProfile.propTypes = {
  addNotice: PropTypes.func.isRequired,
  vanityURLs: PropTypes.arrayOf(PropTypes.any).isRequired,
  influencer: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
};
VanityURLsProfile.defaultProps = {
  influencer: null,
};

const mapStateToProps = state => ({
  influencer: state.influencer.influencer,
  vanityURLs: state.influencer.vanityURLs,
  loading: state.influencer.loading.vanityURLs,
});

export default withNotice(connect(mapStateToProps)(VanityURLsProfile));
