import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import { axios } from 'ApiClient';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '@/shared/helpers/WVUtilities';
import { clearFilterProperty } from 'ILRedux/TableReducerDefault';
import Hook from '@/shared/hooks';
import TimePeriodFilter from '@/shared/components/filter/TimePeriodFilter';
import AccessComponent from '@/shared/components/auth/AccessComponent';
import { goToPage } from '@/shared/helpers/WVUtilities';


const datePickerOptions = [
  { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'last_7_days', label: 'Last 7 days' },
  { value: 'last_30_days', label: 'Last 30 days' },
  {
    value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
  },
];

const colourStyles = {
  multiValue: styles => ({
    ...styles,
    backgroundColor: '#70bbfd',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#a2e1fd',
      color: '#70bbfd',
    },
  }),
};


const Actions = React.memo(({
  downloadCsv, getWinnersDate, type,
}) => {
  console.log('RELOAD ACRIONS');
  const dispatch = useDispatch();
  const [ showModal ] = Hook.useShowModalHook('ConfirmModalDefault');
  const { showSuccess, showError } = Hook.useNoticeHook();
  const reload = Hook.useReloadTableHook(type === 'new-winner' ? '/api/portal/reports/get-new-winners-items-report' : '/api/portal/reports/get-new-winners-report');
  const [showFilters, setShowFilters] = useState(getCookie('approvalStatus') === 'true');

  const [startDate, setStartDate] = Hook.useTableFilterHookString('winnerDatetime.from');
  const [endDate, setEndDate] = Hook.useTableFilterHookString('winnerDatetime.to');
  const [selectedButton, setSelectedButton] = Hook.useTableFilterHookString('winnerDatetime.selectedButton');
  const [selectedBrands, setSelectedBrands, brandOptions] = Hook.useTableFilterHookTags('brand');
  const [selectedAM, setSelectedAM, AMOptions] = Hook.useTableFilterHookTags('affiliateManager');


  useEffect(() => {
    setCookie('approvalStatus', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty());
  };
  const showRecalculateModal = () => {
    // setShowRecalculate(true);
    showModal({
      title: 'Recalculate Winners',
      message: 'Are you sure you want to recalculate winners?',
      processRecord: async() => {
        const resp = await axios.get('/api/portal/reports/get-new-wins-report');
        showSuccess(`Winners Recalculated Successfully: Added ${resp.data.added} Winners`);
        if (getWinnersDate)  getWinnersDate();
        reload();
      },
      errorCatch: (e) => {
        showError(e.message || 'Error In Recalculate Winners');
      },

      confirmButtonCaption: 'Start Recalculation Process',
    })
  }

  return (
    <div>
      <Row>
        <Col sm={8}>
          <TimePeriodFilter
            startDateInitial={startDate}
            endDateInitial={endDate}
            selectedButtonInitial={selectedButton}
            setValue={(param, value, range) => {
              if (param === 'startDate') {
                setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
              }
              if (param === 'endDate') {
                setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
              }
              console.log(range)
              setSelectedButton(range);
            }}
            title=""
            options={datePickerOptions}
            includeToday
          />
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => showRecalculateModal()}>
                Recalculate
              </DropdownItem>
              <AccessComponent showWhenTeam={['management', 'super_powers', 'engineering']}>
                <DropdownItem onClick={() => goToPage('/reports/new-winners-report-settings')}>
                  Settings
                </DropdownItem>
              </AccessComponent>
              <DropdownItem onClick={() => downloadCsv()}>
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <Row>
          <Col sm="4" >
            <FormGroup>
              <Label className="bold-text">
                Brand(s)
              </Label>
              <Select
                isMulti
                name="brandSelect"
                isClearable
                value={selectedBrands}
                options={brandOptions}
                onChange={setSelectedBrands}
                placeholder="Select Brand..."
                style={{ maxHeight: '120px' }}
                styles={colourStyles}
              />
            </FormGroup>
          </Col>
          <Col sm="4" >
            <FormGroup>
              <Label className="bold-text">
                Account Manager(s)
              </Label>
              <Select
                isMulti
                name="selectedAM"
                isClearable
                value={selectedAM}
                options={AMOptions}
                onChange={setSelectedAM}
                placeholder="Select Account Manager..."
                style={{ maxHeight: '120px' }}
                styles={colourStyles}
              />
            </FormGroup>
          </Col>
          <Col sm='auto'></Col>
        </Row>
      )}
    </div>
  );
});
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  getWinnersDate: PropTypes.func,
};
Actions.defaultProps = {
  getWinnersDate: null,
};

export default Actions;
