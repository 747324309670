import React, { useState } from "react";
import { Button, Card, CardBody, Container, Row, Col, CardTitle,
  Modal, ModalHeader, ModalBody
 } from "reactstrap";
import ReactJson from 'react-json-view';
import useDuplicateVanityUrls from './useDuplicateVanityUrls';
import Breadcrumbs from '@/shared/components/BreadCrumbs';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import WVFormatter from '@/shared/helpers/WVFormatter';

const NotAvailable = '--';

const DuplicateRedirects = () => {
  const [expandedRows, setExpandedRows] = useState({});
  const results = useDuplicateVanityUrls();

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const columnTransform = {
    domain: {
      title: 'Domain / Path',
      valueFunc: (r) => {
          return (
            <a href={`https://${r.domain}/${r.localPath}`} target="_blank" rel="noreferrer">
              <strong>{r.domain} / {r.localPath}</strong>
            </a>
          );
      },
      sort: (r) => {
        return r.domain;
      },
    },
    influencer: {
      title: 'Influencer (s)',
      valueFunc: (r) => {
          return (
            <strong>
              <ul>{
                r.documents.filter(d => d).map(doc => {
                  if(!doc.influencer?._id){
                    return <li key={doc._id}>{NotAvailable}</li>
                  }
                  return <li key={doc._id}>
                      <a target="_blank" rel="noreferrer" href={`/influencer/profile/${doc.influencer?._id}`}>
                        {doc.influencer?.fullName}
                      </a>
                    </li>
                })}
              </ul>
            </strong>
          );
      },
      sort: () => {
        return '';
      },
    },
    offer: {
      title: 'Offer (s)',
      valueFunc: (r) => {
          return (
            <strong>
              <ul>{
                r.documents.filter(d => d).map(doc => {
                  return <li key={doc._id}>{doc.offer?.name || NotAvailable}</li>
                })}
              </ul>
            </strong>
          );
      },
      sort: () => {
        return '';
      },
    },
    accountManager: {
      title: 'Account Manager (s)',
      valueFunc: (r) => {
          return (
            <strong>
              <ul>{
                r.documents.filter(d => d).map(doc => {
                  const fullName = `${doc.accountManager?.firstName || ''} ${doc.accountManager?.lastName || ''}`;
                  return <li key={doc._id}>{fullName.trim() || NotAvailable}</li>
                })}
              </ul>
            </strong>
          );
      },
      sort: () => {
        return '';
      },
    },
    createdAt: {
      title: 'Date Created',
      valueFunc: (r) => {
          return (
            <strong>
              <ul>{
                r.documents.filter(d => d).map(doc => {
                  return <li key={doc._id}>{WVFormatter.getFormatedDate(doc.createdAt)}</li>
                })}
              </ul>
            </strong>
          );
      },
      sort: () => {
        return '';
      },
    },
    resultUrl: {
      title: 'Result URL',
      valueFunc: (r) => {
          return (
            <ul>{
              r.documents.filter(d => d).map(doc => {
                return <li key={doc._id} className={doc.is200Status ? '' : 'text-danger'}>
                  <strong>{doc.resultUrl || NotAvailable}</strong>
                </li>
              })
            } </ul>
          );
      },
      width: 440,
      sort: (r) => {
        return r.resultUrl;
      },
    },
    details: {
      title: 'Details',
      valueFunc: (r) => {
          return (
            <div>
              <strong>Count: {r.duplicateCount} </strong>
              <br />
              <hr />
              <Button
                color={expandedRows[r?._id] ? 'secondary' : 'primary'}
                size="sm"
                onClick={() => toggleRow(r?._id)}
              >
                {expandedRows[r?._id] ? "Hide Details" : "Show Details"}
              </Button>
              <Modal isOpen={expandedRows[r?._id]} toggle={() => toggleRow(r?._id)}>
                <ModalHeader toggle={() => toggleRow(r?._id)}>Duplicate Documents</ModalHeader>
                <ModalBody>
                  <ReactJson 
                    src={r.documents}
                    theme="paraiso"
                    collapsed={2}
                    name={false}
                    displayDataTypes={false}
                    quotesOnKeys={false}
                    displayArrayKey={false}
                    iconStyle="circle"
                  />
                </ModalBody>
              </Modal>
            </div>
          );
      },
      sort: (r) => {
        return '';
      },
    }, 
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Vanity Urls', path: '/resources/VanityUrl/actions/list' },
              { title: 'Duplicated VanityUrls', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <Row>
                  <Col sm={12}>
                    <div className="d-flex w-100 justify-content-between" >
                      <h3>Duplicate Vanity URLs</h3>
                    </div>
                  </Col>
                </Row>
              </CardTitle>
              <hr />
              <MatTableForRedux
                hideToolbar
                dataArray={results}
                selectable={false}
                columns={columnTransform}
                isSortable={false}
                defaultOrder={{
                  order: 'desc',
                  orderBy: 'domain',
                }}
                rowsPerPage={10}
              />
            </CardBody>
          </Card>
        </Col>      
      </Row>
    </Container>
  );
};

export default DuplicateRedirects;