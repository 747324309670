import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Row, Col, Spinner, Label, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import { useAsyncDebounce } from 'react-table';
import { axios } from 'ApiClient';
import ReactTableBase from '../../../../../shared/tables/table/ReactTableBase';
import { IconButton } from '@material-ui/core';
// import CheckBox from '../../../../../shared/components/CheckBox';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';


const CollapcedComponent = ({ children, isCollapsed }) => {
  return isCollapsed ? (
    <div>
      {children}
    </div>
  ) : null;
};
CollapcedComponent.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
};

const tableConfig = {
  isEditable: false,
  isResizable: false,
  useFlex: true,
  isSortable: true,
  withPagination: true,
  manualPageSize: [10, 20, 30, 40],
  withSearchEngine: false,
  sortBy: 'rank',
  direction: 'asc',
};
const colourStyles = {
  multiValue: styles => ({
    ...styles,
    backgroundColor: '#70bbfd',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#a2e1fd',
      color: '#70bbfd',
    },
  }),
};

const TopCNewWinnersPanel = ({ version, items, loading, fromDate, toDate }) => {

  const [collapceSelected, setCollapceSelected] = useState([]);
  const [date, setDate] = useState(null);
  const [brandOptions, setBrandOptions] = useState([]);
  const [employeesOptions, setEmployees] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedAM, setSelectedAM] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const downloadCsvCreator = useCallback(() => {
    console.log(fromDate, toDate)
    window.open(`/api/portal/reports/get-new-winners-items-report-csv?filters.winnerDatetime.from=${new Date(fromDate * 1000).toISOString().split('T')[0]}&filters.winnerDatetime.to=${new Date(toDate * 1000).toISOString().split('T')[0]}`, '_blank').focus();
  }, [fromDate, toDate]);

  const downloadCsvBrand = useCallback(() => {
    window.open(`/api/portal/reports/get-new-winners-report-csv?filters.winnerDatetime.from=${new Date(fromDate * 1000).toISOString().split('T')[0]}&filters.winnerDatetime.to=${new Date(toDate * 1000).toISOString().split('T')[0]}`, '_blank').focus();
  }, [fromDate, toDate]);

  const onChange = (id) => {
    setCollapceSelected(() => {
      if (collapceSelected.includes(id)) {
        return collapceSelected.filter((item) => item !== id);
      }
      return [...collapceSelected, id];
    })
  };
  const getWinnersDate = async () => {
    const data = await axios.get('/api/portal/reports/get-new-wins-report-date');
    setDate(data.data.date);
    return data.data.date;
  };
  const fetchBrandOptions = async () => {
    try {
      const resp = await axios.get('/api/portal/scorecard/media-content/get-filters-data-set');
      if (resp?.data?.success) {
        setBrandOptions(resp?.data?.brandOptions);
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const fetchDealOwnerOptions = async () => {
    try {
      const resp = await axios.get('/api/portal/finance/get-deal-owners');
      if (resp?.data?.success) {
        setEmployees(resp.data.dealOwners.map((a) => ({
          value: String(a.affiliateManagerId),
          label: `${a.firstName} ${a.lastName}`,
        })));
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getWinnersDate();
    fetchBrandOptions();
    fetchDealOwnerOptions();
  }, []);

  const columnTransform = [
    {
      id: 'rank',
      Header: () => 'Rank',
      accessor: (p, index) => p.params.index,
      // eslint-disable-next-line max-len
      Cell: p => (<span className='font-weight-bold'>{String(Number(p.value))}</span>),
      width: 200,
      className: 'd-flex align-items-top',
      disableSortBy: false,
    },

    {
      id: 'accountManager',
      Header: () => 'Account Manager',
      accessor: (record) => `${record.params.accountManager.firstName} ${record.params.accountManager.lastName} ${record.params.items.map(item => item.advertiser?.companyName || '').join(' ')}`,
      // eslint-disable-next-line max-len
      Cell: p => (
        <div>
          <div className='py-3 font-weight-bold'>
            {p.row.original.params.accountManager.firstName} {p.row.original.params.accountManager.lastName}
          </div>
          <CollapcedComponent isCollapsed={collapceSelected.includes(p.row.original.id)}>
            <div className='text-muted mt-3 font-weight-bold' style={{ fontSize: '13px' }}>Brand</div>
            <hr className='my-2' />
            <div className='text-muted' style={{ fontSize: '13px' }}>
              {p.row.original.params.items.map((item) => (
                <div key={item._id}>
                  {item.advertiser.companyName}
                </div>
              ))}
            </div>
          </CollapcedComponent>
        </div>
      ),
      width: 200,
      className: '',
      disableSortBy: false,
    },
    {
      id: 'count',
      Header: () => 'Total Winners',
      accessor: (record) => record.params.items.reduce((acc, item) => acc + Number(item.count), 0),
      // eslint-disable-next-line max-len
      Cell: p => (
        <div>
          <div className='py-3 font-weight-bold'>
            {p.value}
          </div>

          <CollapcedComponent isCollapsed={collapceSelected.includes(p.row.original.id)}>
            <div className='text-muted mt-3 font-weight-bold' style={{ fontSize: '13px' }}>Winner Count</div>
            <hr style={{ marginLeft: '-15px' }} className='my-2' />
            <div className='text-muted' style={{ fontSize: '13px' }}>
              {p.row.original.params.items.map((item) => (
                <div key={item._id}>
                  {item.count}
                </div>
              ))}
            </div>
          </CollapcedComponent>
        </div>
      ),
      width: 200,
      className: '',
      disableSortBy: false,
    },
    {
      Header: () => (
        <FormGroup>
          <FormControlLabel className='m-0' control={
            <Checkbox
              disabled={items.length === 0}
              size='medium'
              checked={collapceSelected.length !== items.length}
              onChange={({ target }) => {
                if (!target) return;
                if (target.checked === true) {
                  setCollapceSelected([]);
                } else {
                  setCollapceSelected(items.map((item) => item.id));
                }
              }}
            />
          } label="Collapse" />
        </FormGroup>
      ),
      accessor: () => null,
      id: 'actions',
      width: 50,
      Cell: (p) => {
        console.log(p)
        const isCollapsed = collapceSelected.includes(p.row.original.id);

        return (
          <div style={{ textAlign: 'right', float: 'right' }}>
            <IconButton onClick={() => onChange(p.row.original.id, p.row.index, p.row.original)} size='small'>
              {isCollapsed ? (<i className="fa  fa-angle-right m-0 mx-1" />) : (<i className="fa  fa-angle-down m-0 mx-1" />)}
            </IconButton>
          </div>
        );
      },
      disableSortBy: true,
      className: 'd-flex align-items-start justify-content-end',
    },
  ];

  const setFilterDebounced = useAsyncDebounce(() => {
    const newItems = [];
    items.forEach((item) => {
      let currentItem = null;
      
      const sAM = selectedAM.map((a) => String(a.value));
      if (selectedAM.length === 0 || sAM.includes(String(item.params.accountManager.tuneId))) {
        currentItem = true
      }
      const filtered = currentItem ? item.params.items.filter((i) => selectedBrands.length === 0 || selectedBrands.some((a) => String(a.value) === String(i.advertiser._id))) : [];
      if (filtered.length > 0) {
        newItems.push({ ...item, params: { ...item.params, items: filtered } });
      }
    });
    setFilteredItems(newItems);

  }, 500);

  useEffect(() => {
    if (selectedBrands.length === 0 && selectedAM.length === 0) {
      setFilteredItems(items);
    } else {
      setFilterDebounced();
    }
  }, [selectedBrands, items, selectedAM])


  return (
    <Row>
      <Col xs="12">
        <h4 className="font-weight-bold d-inline-block">New Winners Leaderboard </h4> {date ? (<span className='text-muted'>Last Updated on <span className='font-weight-bold'>{moment(date).format('MM/DD/YYYY hh:mm A')}</span></span>) : null}
      </Col>
      {loading.clicks && (
        <div className="text-center w-100">
          <Spinner color="primary" size="lg" />
        </div>
      )}
      {!loading.clicks && (
        <div style={{ margin: '20px', width: '100%' }}>
          <Row className="mb-0">
            <Col >
              <FormGroup>
                <Label className="bold-text">
                  Brand(s)
                </Label>
                <Select
                  isMulti
                  name="brandSelect"
                  isClearable
                  value={selectedBrands}
                  options={brandOptions}
                  onChange={setSelectedBrands}
                  placeholder="Select Brand..."
                  style={{ maxHeight: '120px' }}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col >
              <FormGroup>
                <Label className="bold-text">
                  Account Manager(s)
                </Label>
                <Select
                  isMulti
                  name="selectedAM"
                  isClearable
                  value={selectedAM}
                  options={employeesOptions}
                  onChange={setSelectedAM}
                  placeholder="Select Account Manager..."
                  style={{ maxHeight: '120px' }}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col xs="auto">
              <FormGroup>
                <Label className="bold-text">
                  &nbsp;
                </Label>
                <UncontrolledButtonDropdown className="w-100">
                  <DropdownToggle
                    id="actionsMenu"
                    caret
                    color="primary"
                    style={{
                      padding: '4px 25px',
                      height: '42px',
                    }}
                  >
                    Download CSV
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => downloadCsvCreator()}>
                      Download Creator Breakdown CSV
                    </DropdownItem>
                    <DropdownItem onClick={() => downloadCsvBrand()}>
                      Download Brand Breakdown CSV
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </FormGroup>
            </Col>

          </Row>
          <ReactTableBase
            key={version}
            columns={columnTransform}
            data={filteredItems}
            tableConfig={tableConfig}
          />
        </div>
      )}
    </Row>
  );
};

TopCNewWinnersPanel.propTypes = {
  version: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.adminDashboard.loading,
  items: state.adminDashboard.topStats.newWinners,
  version: state.adminDashboard.lastFetched.toString(),
  fromDate: state.adminDashboard.fromDate,
  toDate: state.adminDashboard.toDate,
});

export default connect(mapStateToProps)(TopCNewWinnersPanel);

