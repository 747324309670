import React from 'react';
// import moment from 'moment-timezone';
// import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MatTableList from '@/shared/tables/materialTable/TableRedux';
import formater from '@/shared/helpers/WVFormatter';
const winnerChriteria = {
  200: '2 Pieces of Content',
  300: '75% Recoup',
  400: '75% Recoup',
  100: '$1,500 Revenue',
}

const Table = React.memo(({ settings }) => {
  const someWinnerChriteriaTooltipInList = (
    <div>
      Default Criteria:
      {Object.keys(settings['default']).map((key) => (
        <li key={key}>
          <span>{winnerChriteria[key]}</span>
        </li>
      ))}
    </div>
  );

  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Account Manager Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'creator',
          Header: () => 'Creator',
          accessor: (p) => p.params.influencer?.fullName,
          // eslint-disable-next-line max-len
          Cell: p => p.value ? (<>
            <a href={`/influencer/profile/${p.row.original.params.influencer._id}`} className='mr-2' target="_blank"
              rel="noopener noreferrer" title="Show Creator Profile">{p.value}</a>
            <a
              title="Show HubSpot Contact"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-0"
              style={{ fontSize: '16px' }}
              href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${p.row.original.params.influencer.hsContactVid}`}
            >
              <i className="fab fa-hubspot" />
            </a>
          </>) : '',
          width: 250,
          className: 'd-flex align-items-top',
          disableSortBy: true,
        },

        {
          id: 'accountManager',
          Header: () => 'Account Manager',
          accessor: (record) => record.params.accountManager?.firstName || '',
          // eslint-disable-next-line max-len
          Cell: p => `${p.row.original.params.accountManager?.firstName || ''} ${p.row.original.params.accountManager?.lastName || ''}`,
          width: 150,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'brand',
          Header: () => 'Brand',
          accessor: (record) => record.params.advertiser?.companyName,
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 100,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'dealType',
          Header: () => 'Deal Type',
          accessor: (record) => record.params.deal?.dealTerms?.type_of_deal,
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 150,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'dealname',
          Header: () => 'First Deal Name',
          accessor: (record) => record.params.deal?.dealname || null,
          // eslint-disable-next-line max-len
          Cell: p => p.value ? (<>
            <span className='mr-2'>{p.value}</span>
            <a
              title="Show HubSpot Contact"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-0"
              style={{ fontSize: '16px' }}
              href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${p.row.original.params.deal?.hs_object_id}`}
            >
              <i className="fab fa-hubspot" />
            </a>
          </>) : '-',
          width: 250,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'publishDate',
          Header: () => 'First Publish Date',
          accessor: (record) => formater.getServerDate(record.params.publishDate),
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 100,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'dayspublishDate',
          Header: () => 'Days Since First Publish',
          accessor: (record) => {
            const publishDate = new Date(record.params.publishDate);
            const now = new Date();
            const diffTime = Math.abs(now - publishDate);
            return formater.formatNumber(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 0);
          },
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 100,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'winnerDatetime',
          Header: () => 'Winner Date',
          accessor: (record) => formater.getServerDate(record.params.winnerDatetime),
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 100,
          className: '',
          disableSortBy: false,
        },
        {
          id: 'winnerReasonCode',
          Header: () => (<>
            Winner Criteria Met{' '}
            <Tooltip title={someWinnerChriteriaTooltipInList}>
              <IconButton
                id="filtersMenuButton2"
                size="small"
                className="material-table__toolbar-button"
              >
                <i className="fa fa-info-circle" style={{ fontSize: '5em' }} />
              </IconButton>
            </Tooltip>
          </>),
          accessor: (record) => record.params.winnerReasonCode,
          // eslint-disable-next-line max-len
          Cell: p => (settings[p.row.original?.params?.advertiser?._id] || settings['default'])[p.value], // || // settings[p.row.original?.params?.advertiser._id || 'default'][p.value],
          width: 200,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'revenue',
          Header: () => 'Revenue',
          accessor: (record) => {
            const revenue = record.params.conversionsStats?.totalRevenue || 0;
            return record.params.deal.dealTerms.type_of_deal === 'Upfront CPM'
            ? formater.formatCurrency(record.params.deal.dealTerms.bh_cpm_total_cost)
            : formater.formatCurrency(revenue);
          },
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 100,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'costs',
          Header: () => 'Costs',
          accessor: (record) => {
            const billDealType = ['Upfront CPM', 'Upfront Only', 'Hybrid']
            const payout = record.params.conversionsStats?.totalPayout || 0;
            const billCosts = billDealType.includes(record.params.deal.dealTerms.type_of_deal)  
              ? record.params.deal.dealTerms.guaranteed_payment_contract_amount || 0 
              : 0;
            return formater.formatCurrency(payout + billCosts);

          },
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 100,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'GR',
          Header: () => 'Gross Profit',
          accessor: (record) => {

            const billDealType = ['Upfront CPM', 'Upfront Only', 'Hybrid']
            const payout = record.params.conversionsStats?.totalPayout || 0;
            const billCosts = billDealType.includes(record.params.deal.dealTerms.type_of_deal)  
              ? record.params.deal.dealTerms.guaranteed_payment_contract_amount || 0 
              : 0;
  
            const revenue = record.params.deal.dealTerms.type_of_deal === 'Upfront CPM'
              ? record.params.deal.bh_cpm_total_cost
              : record.params.conversionsStats?.totalRevenue || 0;
            return formater.formatCurrency(revenue - billCosts - payout);
          },
            
          // formater.formatCurrency(Number(record.params.conversionsStats?.totalRevenue || 0) - Number(record.params.conversionsStats?.totalPayout || 0)),
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 100,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'conversions',
          Header: () => 'Revenue Conversions',
          accessor: (record) => formater.formatNumber(record.params.conversionsStats?.totalCount || 0, 0),
          // eslint-disable-next-line max-len
          Cell: p => p.value,
          width: 150,
          className: '',
          disableSortBy: true,
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
