const boolToInt = (value) => {
  switch (value) {
    case true: return 1;
    case false: return 0;
    default: return value;
  }
};

exports.boolToYesNo = (value) => {
  switch (value) {
    case true: return 'Yes';
    case false: return 'No';
    default: return value;
  }
};

exports.fixInputCursor = (input, pos) => { input.setSelectionRange(pos, pos); };
exports.getSelectedOption = (opts, selected) => opts.filter(o => o.value === (selected || ''));
exports.toOptions = data => Object.keys(data).map(k => ({ value: boolToInt(k), label: data[k] }));
exports.boolToInt = boolToInt;

exports.yesNoOptions = [
  { value: 1, label: 'Yes' },
  { value: 0, label: 'No' },
];

exports.fromOptions = (opts) => {
  const res = {};
  opts.forEach((o) => { res[o.value] = o.label; });
  return res;
};

exports.fillCheckboxes = (values, keys = []) => {
  const res = {};
  const def = values?.length > 0;
  keys.forEach((k) => { res[k] = !def; });
  if (def) {
    values.forEach((val) => {
      if (keys.includes(val)) {
        res[val] = true;
      }
    });
  }
  return res;
};

exports.selectedCheckboxes = (values, notCheckedValue = null) => {
  const res = [];
  Object.keys(values).forEach((key) => {
    if (values[key]) res.push(key);
  });
  if (!res || res.length === 0) return notCheckedValue;
  return (res.length === 1 && !Array.isArray(notCheckedValue)) ? res[0] : res;
};

exports.splitString = (str, limit) => {
  const haystack = ' ';
  const needle = "\n";
  let pos;
  let len = 0;
  let res = str;
  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === haystack) pos = i;
    if (str[i] === needle) len = i;
    if (i - len > limit && pos) {
      res = `${res.substring(0, pos)}${needle}${res.substring(pos + 1)}`;
      len = pos;
    }
  }
  return res;
};
