import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import CreatorInList from './CreatorInList';
import BrandStatus from './BrandStatus';
import WVFormatter, { formatIntNumber } from '../../../../../../../shared/helpers/WVFormatter';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import CellSelect from '../../../../../../../shared/components/table/CellSelect';
import UserPropertyType from './index';
import CreatorFirstName from './CreatorFirstName';
import CreatorLastName from './CreatorLastName';
import CreatorEmail from './CreatorEmail';
import InfluencelogicStatus from './InfluencelogicStatus';
import TargetBrand from './TargetBrand';
import AnalystUser from './AnalystUser';
import ProgramCell from './ProgramCell';
// import SafetyCheck from './SafetyCheck';
import TableCellContextMenu from './TableCellContextMenu';
// import { countriesBH } from '../../../../../../../shared/helpers/WVConstants';

// const countries = countriesBH();

const Table = React.memo(({
  action,
  resource,
  setSingleSelectedProspectId,
  setSingleSelectedProspectPodcastId,
  setSingleSelectedProspectCurrentEmail,
  setOpenRelatedPodcastModal,
  dispatch,
  addNotice,
  brands,
  setRefreshStatisticsOptionsModalVisible,
  setProspectIdsToDelete,
  setShowDeleteModal,
  goToEditProspectPage,
  version,
  setVersion,
  setDisableNote,
  handleSelectCheckbox,
  // brandSafetySearchTerms,
  // prospectContainerRefresh,
  setCurrentProspect,
  setShowNotesModal,
  setShowDuplicateTargetBrandModal,
  setNewBrand,
  setPortal,
  analysts,
  setMetaTotal,
  showMissingDataModal,
  miningMode,
  setShowCloneModal,
  setShowDetailsModal,
  setShowHistoryModal,
  setOpenTabs,
  setShowBlackListModal,
  setActionBlackListModal,
  recordsRef,
  categories,
  rejectionReasons,
  countryOptions,
}) => (
  <MatTableList
    action={action}
    resource={resource}
    date={new Date()}
    version={version}
    UserPropertyType={UserPropertyType}
    showFilters={false}
    showTitle={false}
    sortBy="createdAt"
    direction="desc"
    v={2}
    noActions
    isResizable={false}
    useFlex
    useCheckboxes
    checkboxHandler={handleSelectCheckbox}
    setMetaTotal={setMetaTotal}
    notFoundProps={{ title: 'No Prospects found' }}
    useUnflatten
    recordsRef={recordsRef}
    columnsSettings={miningMode ? {} : {
      fullSearchName: {
        width: 350,
        className: 'd-flex align-items-center',
        Header: () => 'Creator',
        accessor: () => '',
        Cell: p => useMemo(
          () => (
            <CreatorInList
              record={p.row.original}
              editHandler={() => { goToEditProspectPage(p.row.original.id); }}
              setOpenTabs={setOpenTabs}
              setCurrentProspect={setCurrentProspect}
            />
          ),
          [p.row.original],
        ),
      },
      countryOfResidence: {
        width: 200,
        className: 'd-flex align-items-center',
        accessor: () => '',
        Header: () => 'COR',
        Cell: p => useMemo(
          () => (
            <div>
              <CellSelect
                name="countryOfResidence"
                label="Country of Residence"
                record={p.row.original}
                values={countryOptions}
                update="/api/portal/prospects/post-save"
                updateRecordFunction={p.updateRecordFunction}
                afterSaveFunc={({data: {success, error}}) => {
                  console.log(success, 'Country has been updated');
                  if (!success) {
                    dispatch(addNotice({
                      message: error,
                      type: 'error',
                    }));
                  }
                }}
                styles={{width: '160px', float: 'left' }}
                disableOptionMap
                additionalFieldsToUpdate={{ countryOfResidenceProcessed: false }}
              />
              {/* {p.row.original.params.countryOfResidence ? countries[p.row.original.params.countryOfResidence] || 'Other' : 'Not Assigned'} */}
            </div>
          ),
          [p.row.original],
        ),
        disableSortBy: true,
      },
      // safetyCheck: {
      //   width: 50,
      //   className: 'd-flex align-items-center',
      //   Header: () => '',
      //   Cell: p => (<SafetyCheck record={p.row.original} searchTerms={brandSafetySearchTerms} setCurrentProspect={setCurrentProspect} setOpenTabs={setOpenTabs} />),
      //   disableSortBy: true,
      // },
      createdAt: {
        width: 150,
        className: 'd-flex align-items-center',
        Header: () => 'Create Date (ET)',
        accessor: () => '',
        Cell: p => useMemo(() => (
          <div style={{width: '160px', paddingRight: '10px'}}>
            <span>{WVFormatter.getServerDateTime(p.row.original.params?.createdAt)}</span>
          </div>
        ), [p.row.original]),
        // width: 50,
      },
      analystUser: {
        width: 200,
        className: 'd-flex align-items-center',
        Header: () => 'Analyst',
        Cell: p => useMemo(() => (
          <AnalystUser
            record={p.row.original}
            analysts={analysts}
            afterSaveFunc={() => setVersion((new Date()).toString())}
            updateRecordFunction={p.updateRecordFunction}
          />
        ), [p.row.original]),
      },
      'category.value': {
        width: 200,
        className: 'd-flex align-items-center',
        Header: () => 'Category',
        Cell: p => useMemo(() => (
          <CellSelect
            name="category"
            label="Category"
            record={p.row.original}
            values={categories}
            update="/api/portal/prospects/post-update-category"
            updateRecordFunction={p.updateRecordFunction}
            useHistory
          />
        ), [p.row.original]),
      },
      'rejectionReason.value': {
        width: 200,
        className: 'd-flex align-items-center',
        Header: () => 'Rejection Reason',
        Cell: p => useMemo(() => (
          <CellSelect
            name="rejectionReason"
            label="Rejection Reason"
            record={p.row.original}
            values={rejectionReasons}
            update="/api/portal/prospects/post-save"
            showNotes={() => { setPortal('rejectionReason'); setDisableNote(false); setCurrentProspect(p.row.original); setShowNotesModal(true); }}
            afterSaveFunc={(response) => {
              const { record } = response.data;
              if (['Other', 'Not Brand Safe'].includes(record.params['rejectionReason.value'])) {
                setPortal('rejectionReason'); setDisableNote(false); setCurrentProspect(p.row.original); setShowNotesModal(true);
              }
            }}
            useHistory
          />
        ), [p.row.original]),
      },
      'approvalStatus.influencelogic.status': {
        Cell: p => useMemo(() => (
          <InfluencelogicStatus
            record={p.row.original}
            afterSaveFunc={() => setVersion((new Date()).toString())}
            setShowNotesModal={setShowNotesModal}
            setCurrentProspect={setCurrentProspect}
            setDisableNote={setDisableNote}
            setPortal={setPortal}
            updateRecordFunction={p.updateRecordFunction}
            missingDataFunction={showMissingDataModal}
          />), [p.row.original]),
        width: 200,
        className: 'd-flex align-items-center',
      },
      targetBrandName: {
        Header: () => 'Target Brand',
        Cell: p => useMemo(() => (
          <TargetBrand
            record={p.row.original}
            afterSaveFunc={() => setVersion((new Date()).toString())}
            brands={brands}
            updateRecordFunction={p.updateRecordFunction}
            setCurrentProspect={setCurrentProspect}
            setShowDuplicateTargetBrandModal={setShowDuplicateTargetBrandModal}
            setNewBrand={setNewBrand}
          />), [p.row.original]),
        width: 160,
        className: 'd-flex align-items-center',
      },
      'approvalStatus.brand.status': {
        Header: () => 'Brand Status',
        Cell: p => useMemo(() => (
          <BrandStatus
            record={p.row.original}
            setShowNotesModal={setShowNotesModal}
            setCurrentProspect={setCurrentProspect}
            setDisableNote={setDisableNote}
            setPortal={setPortal}
            updateRecordFunction={p.updateRecordFunction}
            afterSaveFunc={() => setVersion((new Date()).toString())}
          />), [p.row.original]),
        width: 200,
        className: 'd-flex align-items-center',
      },
      program: {
        Header: () => 'Program',
        Cell: p => useMemo(() => (
          <ProgramCell
            record={p.row.original}
            afterSaveFunc={() => setVersion((new Date()).toString())}
            updateRecordFunction={p.updateRecordFunction}
          />), [p.row.original]),
        width: 160,
        className: 'd-flex align-items-center',
      },
      'youtubeStats.influencer_score': {
        Header: () => 'Score',
        Cell: p => useMemo(() => (
          <div
            className="text-center"
          >
            {formatIntNumber(Math.ceil(Number(p.row.original.params.youtubeStats?.influencer_score || 0)))}
          </div>
        ), [p.row.original]),
        width: 80,
        className: 'd-flex align-items-center',
      },
      'youtubeStats.monthly_v30': {
        Cell: p => useMemo(() => (
          <div
            className="text-center"
          >
            {formatIntNumber(p.row.original.params.youtubeStats?.monthly_v30 || 0)}
          </div>
        ), [p.row.original.id]),
        width: 80,
        className: 'd-flex align-items-center',
      },
      tags: {
        Header: () => 'Tags',
        Cell: p => useMemo(() => {
          let value = '—';
          // console.log(p.row.original);
          const { params } = p.row.original;
          if (params?.tags?.length > 0) {
            value = params.tags.join(', ');
          }
          return (
            <Tooltip
              title={value === '—' ? 'No tags added yet' : value}
            >
              <div
                style={{
                  width: '100%',
                  maxWidth: '100px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'pre',
                  cursor: 'pointer',
                }}
              >
                {value}
              </div>
            </Tooltip>
          );
        }, [p.row.original]),
        width: 100,
        className: 'd-flex align-items-center',
      },
      actionProspect: {
        Header: '',
        accessor: () => null,
        id: 'influencer_actions',
        width: 50,
        Cell: (p) => {
          // const ref = useRef();
          // const [modalHistory, showModalHistory] = useState(false);
          // const [modalDetails, showModalDetails] = useState(false);
          const contextMenuOptions = [
            { label: 'Details', handler: () => { setCurrentProspect(p.row.original); setShowDetailsModal(true); } },
            { label: 'Edit', handler: () => { goToEditProspectPage(p.row.original.id); } },
            { label: 'Delete', handler: () => { setProspectIdsToDelete([p.row.original.id]); setShowDeleteModal(true); } },
            { type: 'divider', label: 'divider0' },
            { label: 'Clone', handler: () => { setCurrentProspect(p.row.original); setShowCloneModal(true); }, disabled: p.row.original.params?.inBlackList },
            { type: 'divider', label: 'divider1' },
            {
              label: 'Refresh Statistics',
              handler: () => {
                setSingleSelectedProspectId(p.row.original.id);
                setRefreshStatisticsOptionsModalVisible(true);
              },
            },
            { type: 'divider', label: 'divider2' },
            {
              label: 'Safety Check',
              handler: () => { setCurrentProspect(p.row.original); setOpenTabs(true); },
            },
            { type: 'divider', label: 'divider3' },
            {
              label: 'Change History',
              handler: () => { setCurrentProspect(p.row.original); setShowHistoryModal(true); },
            },
            { type: 'divider', label: 'divider4' },
            {
              label: 'Related Podcasts',
              handler: () => {
                const { params } = p.row.original;
                const titleToSearchBy = params.tubularDetails?.accounts?.find(account => account.platform === 'youtube')?.title;
                const podcastId = params.listenNotesDetails?.podcastId;
                const { email } = params;
                if (titleToSearchBy) {
                  setSingleSelectedProspectId(p.row.original.id);
                  setSingleSelectedProspectPodcastId(podcastId);
                  setSingleSelectedProspectCurrentEmail(email);
                  setOpenRelatedPodcastModal({
                    isOpen: true,
                    title: titleToSearchBy,
                  });
                } else {
                  console.log('Missing youtube account title: ', p.row.original);
                  dispatch(addNotice({
                    message: 'Failed to extract property to search podcasts by.',
                    type: 'warning',
                    duration: 15,
                  }));
                }
              },
            },
          ];
          if (p.row.original.params?.tubularDetails?.tubularCreatorId && !p.row.original.params?.inBlackList) {
            contextMenuOptions.push({ type: 'divider', label: 'divider5' });
            contextMenuOptions.push({
              label: 'Add to Blacklist',
              handler: () => { setCurrentProspect(p.row.original); setShowBlackListModal(true); setActionBlackListModal('add'); },
            });
          }
          if (p.row.original.params?.tubularDetails?.tubularCreatorId && p.row.original.params?.inBlackList) {
            contextMenuOptions.push({ type: 'divider', label: 'divider6' });
            contextMenuOptions.push({
              label: 'Remove from Blacklist',
              handler: () => { setCurrentProspect(p.row.original); setShowBlackListModal(true); setActionBlackListModal('remove'); },
            });
          }
          return (
            <div style={{ paddingTop: '8px' }} className="text-right">
              <TableCellContextMenu options={contextMenuOptions} />
            </div>
          );
        },
        disableSortBy: true,
        className: 'd-flex align-items-center align-middle',
      },
    }}
    customColumns={miningMode ? [
      {
        id: 'fullSearchName',
        Header: () => 'Creator',
        accessor: () => 'fullSearchName',
        Cell: p => useMemo(() => (
          <CreatorInList
            record={p.row.original}
            editHandler={() => { goToEditProspectPage(p.row.original.id); }}
            miningMode
            setOpenTabs={setOpenTabs}
            setCurrentProspect={setCurrentProspect}
          />), [
          p.row.original.id,
          p.row.original.params.youtubeStats?.monthly_v30,
          p.row.original.params.youtubeStats?.influencer_score,
        ]),
        width: 350,
        disableSortBy: false,
        sortable: true,
        className: 'd-flex align-items-center',
      },
      // {
      //   id: 'safetyCheck',
      //   accessor: () => 'safetyCheck',
      //   Header: () => '',
      //   Cell: p => (<SafetyCheck record={p.row.original} searchTerms={brandSafetySearchTerms} setCurrentProspect={setCurrentProspect} setOpenTabs={setOpenTabs} />),
      //   disableSortBy: true,
      //   width: 80,
      //   className: 'd-flex align-items-center',
      // },
      {
        id: 'firstname',
        accessor: () => 'firstname',
        Header: () => 'First Name',
        Cell: p => (<CreatorFirstName record={p.row.original} />),
        disableSortBy: false,
        sortable: true,
        width: 120,
        className: 'd-flex align-items-center',
      },
      {
        id: 'lastname',
        accessor: () => 'lastname',
        Header: () => 'Last Name',
        Cell: p => (<CreatorLastName record={p.row.original} />),
        disableSortBy: false,
        sortable: true,
        width: 120,
        className: 'd-flex align-items-center',
      },
      {
        id: 'email',
        accessor: () => 'email',
        Header: () => 'Email',
        Cell: p => (<CreatorEmail record={p.row.original} />),
        disableSortBy: false,
        sortable: true,
        width: 130,
        className: 'd-flex align-items-center',
      },
      {
        id: 'category.value',
        accessor: () => 'category.value',
        Header: () => 'Category',
        Cell: p => (
          <CellSelect
            name="category"
            label="Category"
            record={p.row.original}
            values={categories}
            update="/api/portal/prospects/post-update-category"
            updateRecordFunction={p.updateRecordFunction}
            useHistory
          />
        ),
        disableSortBy: false,
        sortable: true,
        width: 180,
        className: 'd-flex align-items-center',
      },
      {
        id: 'approvalStatus.brand.targetAdvertiserId',
        accessor: () => 'approvalStatus.brand.targetAdvertiserId',
        Header: () => 'Target Brand',
        Cell: p => useMemo(() => (
          <TargetBrand
            record={p.row.original}
            afterSaveFunc={() => setVersion((new Date()).toString())}
            brands={brands}
            updateRecordFunction={p.updateRecordFunction}
            setCurrentProspect={setCurrentProspect}
            setShowDuplicateTargetBrandModal={setShowDuplicateTargetBrandModal}
            setNewBrand={setNewBrand}
          />), [p.row.original]),
        width: 130,
        className: 'd-flex align-items-center',
        disableSortBy: true,
        sortable: false,
      },
      {
        id: 'approvalStatus.influencelogic.status',
        accessor: () => 'approvalStatus.influencelogic.status',
        Header: () => 'InfluenceLogic Status',
        Cell: p => useMemo(() => (
          <InfluencelogicStatus
            record={p.row.original}
            afterSaveFunc={() => setVersion((new Date()).toString())}
            setShowNotesModal={setShowNotesModal}
            setCurrentProspect={setCurrentProspect}
            setDisableNote={setDisableNote}
            setPortal={setPortal}
            updateRecordFunction={p.updateRecordFunction}
            missingDataFunction={showMissingDataModal}
            miningMode
          />), [p.row.original]),
        width: 200,
        className: 'd-flex align-items-center',
        disableSortBy: false,
        sortable: true,
      },
      {
        id: 'rejectionReason.value',
        accessor: () => 'rejectionReason.value',
        Header: () => 'Rejection Reason',
        Cell: p => (
          <CellSelect
            name="rejectionReason"
            label="Rejection Reason"
            record={p.row.original}
            values={rejectionReasons}
            update="/api/portal/prospects/post-save"
            showNotes={() => { setPortal('rejectionReason'); setDisableNote(false); setCurrentProspect(p.row.original); setShowNotesModal(true); }}
            afterSaveFunc={(response) => {
              const { record } = response.data;
              if (['Other', 'Not Brand Safe'].includes(record.params['rejectionReason.value'])) {
                setPortal('rejectionReason'); setDisableNote(false); setCurrentProspect(p.row.original); setShowNotesModal(true);
              }
            }}
            useHistory
          />
        ),
        disableSortBy: false,
        sortable: true,
        width: 200,
        className: 'd-flex align-items-center',
      },
      {
        id: 'analystUser',
        accessor: () => 'analystUser',
        width: 165,
        className: 'd-flex align-items-center',
        Header: () => 'Analyst',
        Cell: p => useMemo(() => (
          <>
            {p?.row?.original && (
              <AnalystUser
                record={p.row.original}
                analysts={analysts}
                afterSaveFunc={() => setVersion((new Date()).toString())}
                updateRecordFunction={p.updateRecordFunction}
                miningMode={miningMode}
              />
            )}
          </>
        ), [p.row.original]),
        disableSortBy: true,
        sortable: false,
      },
      {
        Header: '',
        accessor: () => null,
        id: 'actionProspect',
        width: 50,
        Cell: (p) => {
          // const ref = useRef();
          // const [modalHistory, showModalHistory] = useState(false);
          // const [modalDetails, showModalDetails] = useState(false);
          const contextMenuOptions = [
            { label: 'Details', handler: () => { setCurrentProspect(p.row.original); setShowDetailsModal(true); } },
            { label: 'Edit', handler: () => { goToEditProspectPage(p.row.original.id); } },
            { label: 'Delete', handler: () => { setProspectIdsToDelete([p.row.original.id]); setShowDeleteModal(true); } },
            // { type: 'divider', label: 'divider0' },
            // { label: 'Clone', handler: () => { setCurrentProspect(unflatten(p.row.original)); setShowCloneModal(true); } },
            { type: 'divider', label: 'divider1' },
            {
              label: 'Refresh Statistics',
              handler: () => {
                setSingleSelectedProspectId(p.row.original.id);
                setRefreshStatisticsOptionsModalVisible(true);
              },
            },
            { type: 'divider', label: 'divider2' },
            {
              label: 'Safety Check',
              handler: () => { setCurrentProspect(p.row.original); setOpenTabs(true); },
            },
            { type: 'divider', label: 'divider3' },
            {
              label: 'Change History',
              handler: () => { setCurrentProspect(p.row.original); setShowHistoryModal(true); },
            },
            { type: 'divider', label: 'divider4' },
            {
              label: 'Related Podcasts',
              handler: () => {
                const { params } = p.row.original;
                const titleToSearchBy = params.tubularDetails?.accounts?.find(account => account.platform === 'youtube')?.title;
                const podcastId = params.listenNotesDetails?.podcastId;
                const { email } = params;
                if (titleToSearchBy) {
                  setSingleSelectedProspectId(p.row.original.id);
                  setSingleSelectedProspectPodcastId(podcastId);
                  setSingleSelectedProspectCurrentEmail(email);
                  setOpenRelatedPodcastModal({
                    isOpen: true,
                    title: titleToSearchBy,
                  });
                } else {
                  console.log('Missing youtube account title: ', p.row.original);
                  dispatch(addNotice({
                    message: 'Failed to extract property to search podcasts by.',
                    type: 'warning',
                    duration: 15,
                  }));
                }
              },
            },
          ];
          return (
            <div style={{ paddingTop: '8px' }} className="text-right">
              {p?.row?.original && (
                <>
                  <TableCellContextMenu options={contextMenuOptions} />
                  {/* <TabsOrModal record={p.row.original} ref={ref} searchTerms={brandSafetySearchTerms} /> */}
                  {/* <StatusHistoryModal record={p.row.original} modal={modalHistory} setModalVisible={showModalHistory} /> */}
                </>
              )}
            </div>
          );
        },
        disableSortBy: true,
        className: 'd-flex align-items-center align-middle',
      },
    ] : []}
  />
), (a, a1) => _.isEqual(a.version, a1.version) && _.isEqual(a.brands, a1.brands));

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  setVersion: PropTypes.func.isRequired,
  setCurrentProspect: PropTypes.func.isRequired,
  setShowNotesModal: PropTypes.func.isRequired,
  setPortal: PropTypes.func.isRequired,
  // prospectContainerRefresh: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,

  setSingleSelectedProspectId: PropTypes.func.isRequired,
  setSingleSelectedProspectPodcastId: PropTypes.func.isRequired,
  setSingleSelectedProspectCurrentEmail: PropTypes.func.isRequired,
  setOpenRelatedPodcastModal: PropTypes.func.isRequired,
  setRefreshStatisticsOptionsModalVisible: PropTypes.func.isRequired,
  setProspectIdsToDelete: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
  goToEditProspectPage: PropTypes.func.isRequired,
  setDisableNote: PropTypes.func.isRequired,
  setMetaTotal: PropTypes.func.isRequired,
  showMissingDataModal: PropTypes.func.isRequired,
  setShowDuplicateTargetBrandModal: PropTypes.func,
  setNewBrand: PropTypes.func,

  handleSelectCheckbox: PropTypes.func.isRequired,
  // brandSafetySearchTerms: PropTypes.arrayOf(PropTypes.any).isRequired,
  analysts: PropTypes.arrayOf(PropTypes.any).isRequired,

  dispatch: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  miningMode: PropTypes.bool,

  setShowCloneModal: PropTypes.func,
  setShowDetailsModal: PropTypes.func,
  setShowHistoryModal: PropTypes.func,
  setShowBlackListModal: PropTypes.func,
  setActionBlackListModal: PropTypes.func,
  setOpenTabs: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recordsRef: PropTypes.any.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  rejectionReasons: PropTypes.arrayOf(PropTypes.any).isRequired,
  countryOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Table.defaultProps = {
  setShowDuplicateTargetBrandModal: () => {},
  setNewBrand: () => {},
  miningMode: false,
  setShowCloneModal: () => {},
  setShowDetailsModal: () => {},
  setShowHistoryModal: () => {},
  setShowBlackListModal: () => {},
  setActionBlackListModal: () => {},
};
export default Table;
