import React from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Col, Label, Row } from 'reactstrap';
import SelectAsync from 'react-select/async';
// import ApiClient from 'ApiClient';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';

class CreatorDealPortalAgentSelect extends React.Component {
  static async loadAgentUsersOptions(inputValue) {
    const response = await axios.get('/api/portal/agent/get-agent-search', {
      params: {
        value: inputValue,
      },
    });
    // const api = new ApiClient();
    // const records = await api.searchRecords({
    //   resourceId: 'AgentUser',
    //   query: inputValue,
    // });
    console.log(response.data.records);
    const agents = response.data.records ? response.data.records.map(record => ({
      value: record.id,
      label: record.params.email !== null ? record.params.email : `${record.params.firstname} ${record.params.lastname}`,
      record,
    })) : [];
    return [{ value: null, label: '-- Create Agent User --', record: { params: { _id: null, firstname: '-- Create Agent User --', lastname: '' } } }].concat(agents);
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      dataLoaded: false,
      agentUser: false,
      label: 'Agent Portal User Email',
      showAddNewAgentButton: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleChangeAgentUser = this.handleChangeAgentUser.bind(this);
    this.handleClickCreateAgent = this.handleClickCreateAgent.bind(this);
  }

  componentDidMount() {
    const { hubilAgentRecord } = this.props;
    const { loading, dataLoaded } = this.state;

    if (!dataLoaded && !loading) {
      this.fetchData(hubilAgentRecord.params.hs_object_id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      reloadAgent,
      hubilAgentRecord,
      selectedAgentOption,
    } = this.props;
    console.log(hubilAgentRecord);
    if (prevProps.reloadAgent === null) {
      if (reloadAgent === hubilAgentRecord.params.hs_object_id) {
        this.fetchData(hubilAgentRecord.params.hs_object_id);
      }
    }
    if (selectedAgentOption?.value !== prevProps.selectedAgentOption?.value) {
      this.handleChangeAgentUser(selectedAgentOption);
    }
  }

  fetchData(hsVid) {
    if (hsVid) {
      this.setState({
        loading: true,
        label: 'Searching...',
      });
      const { afterFetch } = this.props;
      console.log(hsVid);
      axios({
        method: 'get',
        url: `/api/portal/agent/find-agent-by-hs-vid?hsContactVid=${hsVid}`,
      }).then((response) => {
        if (response.data.success) {
          console.log(response.data);
          if (response.data.agentUser) {
            this.setState({
              loading: false,
              agentUser: response.data.agentUser,
              label: 'Agent Portal User Email',
            }, () => {
              afterFetch();
            });
          } else {
            this.setState({
              loading: false,
              agentUser: response.data.agentUser,
              label: 'Select Agent Portal User',
            });
          }
        }
      });
    }
  }

  handleChangeAgentUser(selected) {
    // console.log(selected);
    if (selected.label === '-- Create Agent User --') {
      this.setState({ showAddNewAgentButton: true });
    } else {
      this.setState({ showAddNewAgentButton: false });
      const { hubilAgentRecord } = this.props;
      if (hubilAgentRecord.params.hs_object_id) {
        axios({
          method: 'post',
          url: '/api/portal/agent/set-agent-hs-contact-vid',
          data: { _id: selected.record.id, hsContactVid: hubilAgentRecord.params.hs_object_id },
        });
      }
    }
  }

  handleClickCreateAgent(event) {
    const { openDialogAgentUser, hubilAgentRecord } = this.props;
    openDialogAgentUser(hubilAgentRecord);
    event.persist();
  }

  render() {
    const {
      selectedAgentOption,
      setSelectedAgentOption,
    } = this.props;
    const {
      loading,
      agentUser,
      label,
      showAddNewAgentButton,
    } = this.state;
    const { hubilAgentRecord } = this.props;
    return (
      <div>
        {hubilAgentRecord.params.hs_object_id !== null && (
          <Label>{label}</Label>
        )}
        {loading && (
          <div>
            <img src="/images/loading.gif" style={{ width: '40px' }} alt="" />
          </div>
        )}
        {!loading && agentUser && (
          <>
            <div style={{ marginTop: '5px' }}>{agentUser.email}</div>
          </>
        )}
        {hubilAgentRecord.params.hs_object_id !== null && !loading && !agentUser && (
          <Row>
            <Col md="8" xs="8">
              <SelectAsync
                value={selectedAgentOption}
                defaultOptions
                loadOptions={CreatorDealPortalAgentSelect.loadAgentUsersOptions}
                onChange={setSelectedAgentOption}
                placeholder="Select Agent Portal User"
              />
            </Col>
            <Col md="4" xs="4">
              {showAddNewAgentButton && (
                <StyledButton
                  type="button"
                  className="is-primary"
                  onClick={this.handleClickCreateAgent}
                  style={{ marginTop: '3px' }}
                >
                  Create Agent User
                </StyledButton>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

CreatorDealPortalAgentSelect.propTypes = {
  hubilAgentRecord: PropTypes.objectOf(PropTypes.any).isRequired,
  openDialogAgentUser: PropTypes.func.isRequired,
  reloadAgent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  afterFetch: PropTypes.func.isRequired,
  selectedAgentOption: PropTypes.objectOf(PropTypes.any),
  setSelectedAgentOption: PropTypes.func.isRequired,
};

CreatorDealPortalAgentSelect.defaultProps = {
  reloadAgent: null,
  selectedAgentOption: null,
};

export default CreatorDealPortalAgentSelect;
