import React, { useEffect, useState, useRef } from 'react';
import {
  // Alert,
  Button,
  Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { axios } from 'ApiClient';
import { connect } from 'react-redux';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ReactTags from "react-tag-autocomplete";
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import { getAdminUsers } from '../../../../../../redux/reducers/admin/ResearchReducer';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
import InternalRequestModal from './custom/modals/InternalRequestModal';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';
import WVValidator from '../../../../../../shared/helpers/WVValidator';
import { fetchDictionary } from '../../../../../../shared/helpers/WVRequest';
import WVFormatter from '@/shared/helpers/WVFormatter';
import { countriesBH } from '../../../../../../shared/helpers/WVConstants';

const tubularChannelsArray = ['youtube', 'instagram', 'facebook', 'twitter', 'tiktok'];
const channelsArray = ['YouTube', 'Instagram', 'Facebook', 'Twitter', 'TikTok'];
const hsMediaChannelPlatforms = [
  { label: 'YouTube', value: 'youtube_channel_url', domain: 'youtube.com' },
  { label: 'Instagram', value: 'instagram_channel', domain: 'instagram.com' },
  { label: 'Facebook', value: 'facebook_channel_url', domain: 'facebook.com' },
  { label: 'Twitter', value: 'twitch_account', domain: 'twitter.com' },
  // { label: 'TikTok', value: 'tiktok_channel_url' },
  // { label: 'Podcast', value: 'apple_podcast_url' },
];

const AddInternalProspectRequest = ({
  dispatch,
  currentAdminUser,
  adminUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [allowDuplicates, setAllowDuplicates] = useState(true);
  const [prospectData, setProspectData] = useState({});
  const [mediaChannelsData, setMediaChannelsData] = useState({});
  const [podcastData, setPodcastData] = useState({});
  const [brand, setBrand] = useState({ value: null, label: '-- Not Assigned --' });
  const [prospectOwner, setProspectOwner] = useState({
    value: currentAdminUser ? currentAdminUser._id : null,
    label: currentAdminUser ? `${currentAdminUser.firstName} ${currentAdminUser.lastName}` : '-- Not Assigned --',
    disabled: true,
  });
  const [submittedBy, setSubmittedBy] = useState({
    value: currentAdminUser ? currentAdminUser._id : null,
    label: currentAdminUser ? `${currentAdminUser.firstName} ${currentAdminUser.lastName}` : '-- Not Assigned --',
  });
  const [errors, setErrors] = useState({});
  const [ilNotes, setIlNotes] = useState('');
  const [platform, setPlatform] = useState({ label: 'YouTube', value: 'youtube_channel_url' });
  const [mediaUrl, setMediaUrl] = useState('');
  const [disableSearch, setDisableSearch] = useState(true);
  const [disableMediaProperties, setDisableMediaProperties] = useState(true);
  const [podcastGenresOptionsLoading, setPodcastGenresOptionsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [podcastGenresOptions, setPodcastGenresOptions] = useState([]);
  const [podcastGenres, setPodcastGenres] = useState([]);
  const [tubularData, setTubularData] = useState({});
  const [modal, setModal] = useState(false);
  const [saveResults, setSaveResults] = useState({ message: 'Success', data: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Creator was found');
  const [alertColor, setAlertColor] = useState('success');
  const [blacklistAlert, setBlacklistAlert] = useState({
    status: false,
    type: 'email',
  });
  const [tags, setTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [corValue, setCorValue] = useState({ value: '', label: '' });
  const [countryOptions, setCountryOptions] = useState([]);

  const mapCountriesOptions = () => {
    const countries = countriesBH();
    const co =  Object.entries(countries).map(([countryCode, countries]) => ({ value: countryCode, label: countries }));
    setCountryOptions(co);
  };

  const requiredFields = ['firstname', 'lastname', 'email', 'brand', 'prospectOwner', 'category', 'mediaUrl'];
  const refs = {
    firstname: useRef(null),
    lastname: useRef(null),
    email: useRef(null),
    brand: useRef(null),
    prospectOwner: useRef(null),
    category: useRef(null),
    mediaUrl: useRef(null),
    reactTags: useRef([]),
  };
  const prospectOwners = adminUsers.filter(row => row.hsUserId);
  prospectOwners.unshift({ value: null, label: '-- Not Assigned --', disabled: true });

  const setProspectProperty = (field, value) => {
    // alert('setProspectProperty '+field)

    /* eslint-disable-next-line arrow-body-style */
    setProspectData((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
    setErrors({
      ...errors,
      [field]: false,
    });
  };
  const setMediaChannelsProperty = (platformItem, field, value) => {
    setMediaChannelsData({
      ...mediaChannelsData,
      [platformItem]: {
        ...mediaChannelsData[platformItem],
        [field]: value,
      },
    });
  };
  const setPodcastProperty = (field, value) => {
    setPodcastData({
      ...podcastData,
      [field]: value,
    });
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
  };

  const fetchTags = () => {
    axios({
      method: 'get',
      url: '/api/portal/research/get-prospects-tags?id=',
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
        setTags(response.data.tags);
      }
    }).catch((err) => {
      console.log(err);
      // addNotice({
      //   message: 'An error occurred while loading the tags. Please try again.',
      //   type: 'error',
      // });
    });
  };

  const fetchDictionaryData = async () => {
    await fetchDictionary('PROSPECT_CATEGORIES').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        setCategories(_categories.map(row => ({ label: row, value: row })));
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchPodcastGenres = () => {
    setPodcastGenresOptionsLoading(true);
    axios.get('/api/portal/research/get-podcast-genres').then((res) => {
      if (res.data && res.data.success) {
        if (res.data.podcastGenresOptions) {
          setPodcastGenresOptions(res.data.podcastGenresOptions);
        }
      }
      setPodcastGenresOptionsLoading(false);
    }).catch((error) => {
      console.error(error);
      setPodcastGenresOptionsLoading(false);
    });
  };

  const loadBrandsOptions = async (inputValue) => {
    const response = await axios({
      method: 'get',
      url: `/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`,
    });
    if (response.data.success) {
      const res = [
        { value: null, label: '-- Not Assigned --' },
      ];
      response.data.brands.forEach((record) => {
        res.push({
          value: record._id,
          label: record.companyName,
          record,
        });
      });
      return res;
    }
    return [];
  };

  const checkBlacklistedEmail = (email) => {
    if (email.includes('@')) {
      axios.get(`/api/portal/blacklist/get-item-by-email?email=${email}`).then((res) => {
        if (res.data.success && res.data.result) {
          setBlacklistAlert({ status: true, type: 'email' });
        } else {
          setBlacklistAlert({ status: false, type: 'email' });
        }
      });
    }
  };

  const changeCategory = (selected) => {
    setProspectProperty('category', selected?.value);
  };

  const changePlatform = (selected) => {
    setPlatform(selected);
    setMediaUrl('');
    setDisableSearch(true);
    setMediaChannelsData({});
    setDisableMediaProperties(true);
  };

  const validateMediaUrl = (value) => {
    if (value) {
      const urlItem = hsMediaChannelPlatforms.find(item => item.label === platform.label);
      if (value.toString().includes(urlItem.domain)) {
        setDisableSearch(false);
      } else {
        setDisableSearch(true);
      }
    }
  };
  const checkRequiredFields = () => {
    let noErrors = true;
    requiredFields.forEach((field) => {
      if (!prospectData[field]) {
        noErrors = false;
        if (!disableSearch) setDisableSearch(true); setDisableMediaProperties(true);
      }
      if (field === 'mediaUrl' && noErrors && mediaUrl.length > 10) {
        validateMediaUrl(mediaUrl);
      }
    });
  };

  const changeMediaUrl = (e) => {
    setMediaUrl(e.target.value);
    setProspectProperty('mediaUrl', e.target.value);
    if (e.target.value?.length > 10) checkRequiredFields();
    if (e.target.value?.length < 11 && !disableSearch) setDisableSearch(true); setDisableMediaProperties(true);
    if (errors.mediaUrl && e.target.value?.length > 0) setErrors({ ...errors, mediaUrl: false });
  };


  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  const uploadTubularData = () => {
    setLoading(true);
    setMediaChannelsData({});
    // const url = mediaChannelsData.youtube?.url || mediaChannelsData.instagram?.url || mediaChannelsData.facebook?.url || mediaChannelsData.twitter?.url;
    axios.get(`/api/portal/prospects/get-data-from-tubular?searchQuery=${mediaUrl}`).then((response) => {
      // console.log('RESPONSE', response.data);
      if (response.data.success && response.data.creator) {
        const mediaData = {};
        response.data.creator.accounts.forEach((account) => {
          if (tubularChannelsArray.includes(account.platform)) {
            if (account.platform === 'youtube' && platform?.value === 'youtube_channel_url') {
              setMediaUrl(account.account_url);
            }
            mediaData[account.platform] = {
              channel: account.title,
              url: (platform.label.toLowerCase() === account.platform && account.platform !== 'youtube') ? mediaUrl : account.account_url,
            };
          }
        });
        setMediaChannelsData({
          ...mediaData,
        });
        setTubularData(response.data.creator);
        if (response.data.creator.country && countryOptions.length > 0) {
          const result = countryOptions.find(item => (item.value === response.data.creator.country));
          setCorValue(result);
        }
        console.log(response.data.creator);
        axios.get(`/api/portal/blacklist/get-item-by-creator-id?tubularCreatorId=${response.data.creator.creator_id}`).then((resp) => {
          if (resp.data.success) {
            if (resp.data.result) {
              setBlacklistAlert({ status: true, type: 'creator' });
            } else {
              setBlacklistAlert({ status: false, type: 'creator' });
            }
          }
        });
        setAlertMessage('Creator was found.');
        setAlertColor('success');
        setShowAlert(true);
      }
      if (response.data.success && !response.data.creator) {
        setMediaChannelsData({
          [platform.label.toLowerCase()]: {
            channel: `${prospectData.firstname} ${prospectData.lastname}`,
            url: mediaUrl,
          },
        });
        setAlertMessage('Creator was not found.');
        setAlertColor('warning');
        setShowAlert(true);
        setTubularData({});
      }
      setLoading(false);
      setDisableMediaProperties(false);
    });
  };

  const saveProspect = () => {
    setLoading(true);
    const data = {
      prospectData: {
        ...prospectData,
        firstname: prospectData.firstname.trim(),
        lastname: prospectData.lastname.trim(),
        email: prospectData.email.trim(),
        submittedBy: `${currentAdminUser.firstName || ''} ${currentAdminUser.lastName || ''}`,
        allowDuplicates,
      },
      mediaChannelsData: { ...mediaChannelsData },
      tubularData: { ...tubularData },
      podcastData: { ...podcastData },
      ilNotes,
      tags,
    };
    const validationErrors = {};

    requiredFields.forEach((field) => {
      switch (field) {
        case 'firstname':
          if (!data.prospectData.firstname || !WVValidator.isDoubleQuoteFree(data.prospectData.firstname)) validationErrors.firstname = true;
          break;
        case 'lastname':
          if (!data.prospectData.lastname || !WVValidator.isDoubleQuoteFree(data.prospectData.lastname)) validationErrors.lastname = true;
          break;
        case 'email':
          if (!data.prospectData.email || !WVValidator.isEmail(data.prospectData.email)) validationErrors.email = true;
          break;
        default:
          if (!data.prospectData[field]) validationErrors[field] = true;
          break;
      }
    });
    if (!prospectOwner.value) validationErrors.prospectOwner = true;
    setErrors(validationErrors);
    // console.log(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      axios.post('/api/portal/research/add-internal-prospect-request', { data }).then((response) => {
        // console.log('Resp', response);
        if (response.data.success) {
          setSaveResults(response.data.result);
          setLoading(false);
          setModal(true);
        } else {
          setLoading(false);
          console.log('Internal prospect was not added.');
        }
      });
    } else {
      refs[Object.keys(validationErrors)[0]]?.current?.focus();
      setTimeout(() => setLoading(false), 5000);
    }
  };

  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  useEffect(() => {
    if (adminUsers?.length < 1) {
      dispatch(getAdminUsers(false));
    }
    fetchDictionaryData();
    fetchPodcastGenres();
    fetchTags();
    setProspectProperty('prospectOwner', `${currentAdminUser._id}`);
    setProspectProperty('adminUser', `${currentAdminUser._id}`);
    mapCountriesOptions();
  }, []);

  useEffect(() => {
    const search = new URLSearchParams(document.location.search);

    if (search && search.has('channel')) {
      /* eslint-disable-next-line no-alert */

      // setTimeout(() => {
      try {
        let channelURL = search.get('channel');
        if (channelURL.indexOf('http') === -1) {
          channelURL = atob(channelURL);
        }
        changeMediaUrl({ target: { value: search.get('channel') } });
      } catch (e) {
        console.error(e);
      }
      // }, 200);
    }
  }, []);

  useEffect(() => {
    checkRequiredFields();
  }, [prospectData]);

  return (
    <Container className="dashboard full-width">
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Add Internal Prospect Request</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <div className="theme-light checkbox-in-prospects">
            <FormGroup style={{ fontSize: '125%' }}>
              <CheckBoxField
                name="allowDuplicates"
                label="Allow duplicate Prospects when Target Brand is unique"
                value={allowDuplicates}
                onChange={(e) => { setAllowDuplicates(e ? e.target.checked : true); }}
              />
            </FormGroup>
          </div>
          <Row>
            <Col md={4}>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor="firstname">First Name</label>
                <Input
                  innerRef={refs.firstname}
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder=""
                  autoComplete="off"
                  onChange={e => setProspectProperty('firstname', e.target.value)}
                  value={prospectData.firstname || ''}
                />
              </FormGroup>
              <div className="grey-small-text grey-minus-margin" style={errors.firstname ? { color: 'red' } : {}}>Required</div>
            </Col>
            <Col md={4}>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor="lastname">Last Name</label>
                <Input
                  innerRef={refs.lastname}
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder=""
                  autoComplete="off"
                  onChange={e => setProspectProperty('lastname', e.target.value)}
                  value={prospectData.lastname || ''}
                />
              </FormGroup>
              <div className="grey-small-text grey-minus-margin" style={errors.lastname ? { color: 'red' } : {}}>Required</div>
            </Col>
            <Col md={4}>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor="email">Email</label>
                <Input
                  innerRef={refs.email}
                  type="text"
                  id="email"
                  name="email"
                  placeholder=""
                  autoComplete="off"
                  onChange={e => setProspectProperty('email', e.target.value)}
                  onBlur={e => checkBlacklistedEmail(e.target.value)}
                  value={prospectData.email || ''}
                  // disabled={loading}
                />
              </FormGroup>
              <div className="grey-small-text grey-minus-margin" style={errors.email ? { color: 'red' } : {}}>Required</div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>Target Brand</Label>
                <SelectAsync
                  ref={refs.brand}
                  cacheOptions
                  value={brand}
                  defaultOptions
                  loadOptions={loadBrandsOptions}
                  onChange={(selected) => {
                    setBrand(selected);
                    if (selected.value) {
                      setProspectProperty('brand', selected.value);
                      setErrors({
                        ...errors,
                        brand: false,
                      });
                    }
                  }}
                  placeholder=""
                />
              </FormGroup>
              <div className="grey-small-text grey-minus-margin" style={errors.brand ? { color: 'red' } : {}}>Required</div>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Prospect Owner</Label>
                <Select
                  ref={refs.prospectOwner}
                  value={prospectOwner}
                  options={prospectOwners}
                  onChange={(selected) => {
                    setProspectOwner(selected);
                    if (selected.value) {
                      setProspectProperty('prospectOwner', selected.value);
                      setErrors({
                        ...errors,
                        prospectOwner: false,
                      });
                    }
                  }}
                  isOptionDisabled={option => option.disabled}
                  placeholder=""
                  menuPlacement="auto"
                />
              </FormGroup>
              <div className="grey-small-text grey-minus-margin" style={errors.prospectOwner ? { color: 'red' } : {}}>Required</div>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Submitted By</Label>
                <Select
                  value={submittedBy}
                  options={adminUsers}
                  onChange={(selected) => {
                    setSubmittedBy(selected);
                    if (selected.value) {
                      setProspectProperty('adminUser', selected.value);
                    }
                  }}
                  placeholder=""
                  menuPlacement="auto"
                />
              </FormGroup>
            </Col>
            <Col md={3} className="edit-prospect-tags">
              <FormGroup>
                <Label>Tags</Label>
                <ReactTags
                  ref={refs.reactTags}
                  tags={tags}
                  suggestions={tagsSuggestions}
                  onDelete={onDeleteTag}
                  onAddition={onAdditionTag}
                  delimiters={['Enter', ',']}
                  allowNew
                  placeholderText="Tags"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="2" md="6">
              <FormGroup>
                <Label>Category</Label>
                <Select
                  ref={refs.category}
                  value={categories.filter(row => row.value === prospectData.category)}
                  options={categories}
                  onChange={changeCategory}
                />
              </FormGroup>
              <div className="grey-small-text grey-minus-margin">Required</div>
            </Col>
            <Col xl="3" md="6">
              <FormGroup>
                <Label>InfluenceLogic Status Notes</Label>
                <Input
                  type="textarea"
                  id="ilNotesEditor"
                  name="ilNotesEditor"
                  value={ilNotes}
                  onChange={(e) => { setIlNotes(e.target.value); }}
                  style={{ height: '38px' }}
                />
              </FormGroup>
            </Col>
            <Col xl="2" lg="6">
              <FormGroup>
                <Label>Platform</Label>
                <Select
                  value={platform}
                  options={hsMediaChannelPlatforms}
                  onChange={changePlatform}
                  placeholder=""
                  // isClearable
                />
                {/* deal.errors.dealId && (<span className="text-danger">{deal.errors.dealId}</span>) */}
              </FormGroup>
              <div className="grey-small-text grey-minus-margin">Media URL Platform (Required)</div>
            </Col>
            <Col xl="5" lg="6">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormGroup style={{ width: '92%' }}>
                  <Label>Media Url</Label>
                  <Input
                    innerRef={refs.mediaUrl}
                    type="text"
                    id="media_url_contact"
                    name="media_url_contact"
                    onChange={changeMediaUrl}
                    value={mediaUrl}
                    // onBlur={validateMediaUrl}
                  />
                  {/* contact.errors.first_name && (<span className="text-danger">{contact.errors.first_name}</span>) */}
                </FormGroup>
                <div>
                  <StyledButton
                    type="button"
                    className="is-primary"
                    onClick={uploadTubularData}
                    style={{ marginTop: '33px', marginLeft: '10px', width: '130px' }}
                    disabled={disableSearch}
                  >
                    {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                    {!loading && <span className="lnr lnr-magnifier font-weight-bold">  </span>}
                    <span>Search</span>
                  </StyledButton>
                </div>
              </div>
              <div className="grey-small-text grey-minus-margin" style={errors.mediaUrl ? { color: 'red' } : {}}>Media URL (Required)</div>
            </Col>
          </Row>
          {blacklistAlert.status && (
            <Alert color="warning" className="w-100 mt-2">
              <p className="py-2 text-dark">
                Creator is in Blacklist. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
              </p>
            </Alert>
          )}
          <Alert color={alertColor} isOpen={showAlert} toggle={() => { setShowAlert(false); }} className="w-100 standard-close-cross">
            <p className="py-2" style={{ color: '#212529' }}>
              {alertMessage}
            </p>
          </Alert>
          <div className="dashboard modal__body finance-form" style={{ fontSize: '100%' }}>
            <h4>Media Channel and COR Properties</h4>
            <hr />
            <Row>
              <Col md={6}>
                <div className="grey-small-text grey-minus-margin mb-3">Specify the Contact&apos;s Media Channel Details</div>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Country Of Residence</Label>
                  <Select
                    value={corValue}
                    onChange={setCorValue}
                    options={countryOptions}
                    isDisabled
                    // menuPlacement="auto"
                    // menuPortalTarget={document.body}
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            {channelsArray.map(channel => (
              <div key={`channel-${channel}`} className="mb-2">
                <h5 style={{ fontSize: '17px', marginBottom: '5px', fontWeight: 'bold' }}>{channel}</h5>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor={`${channel.toLowerCase()}_channel_name`}>{`${channel} Channel Name`}</label>
                      <Input
                        type="text"
                        id={`${channel.toLowerCase()}_channel_name`}
                        name={`${channel.toLowerCase()}_channel_name`}
                        placeholder=""
                        autoComplete="off"
                        onChange={e => setMediaChannelsProperty(channel.toLowerCase(), 'channel', e.target.value)}
                        value={mediaChannelsData[channel.toLowerCase()]?.channel || ''}
                        disabled={disableMediaProperties || blacklistAlert.status}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor={`${channel.toLowerCase()}_channel_url`}>{`${channel} ${channel === 'TikTok' ? 'URL' : 'Channel URL'}`}</label>
                      <Input
                        type="text"
                        id={`${channel.toLowerCase()}_channel_url`}
                        name={`${channel.toLowerCase()}_channel_url`}
                        placeholder=""
                        autoComplete="off"
                        readOnly={platform?.value?.includes(channel.toLowerCase())}
                        onChange={e => setMediaChannelsProperty(channel.toLowerCase(), 'url',  WVFormatter.normalizeUrl(e.target.value))}
                        value={mediaChannelsData[channel.toLowerCase()]?.url || ''}
                        disabled={disableMediaProperties || blacklistAlert.status}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            ))}
            <h5 style={{ fontSize: '17px', marginBottom: '5px', fontWeight: 'bold' }}>Podcast</h5>
            <Row>
              <Col md={6}>
                <FormGroup>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                  <label htmlFor="podcast_title">Podcast Title</label>
                  <Input
                    type="text"
                    id="podcast_title"
                    name="podcast_title"
                    placeholder=""
                    autoComplete="off"
                    onChange={e => setPodcastProperty('title', e.target.value)}
                    value={podcastData.title || ''}
                    disabled={disableMediaProperties || blacklistAlert.status}
                  />
                  {/* errors.podcast_title && (<span className="text-danger">{errors.podcast_title}</span>) */}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="w-100 research-watchlists">
                    <Label>
                      Podcast Genre(s)
                    </Label>
                    <Select
                      isMulti
                      name="podcastGenresSelect"
                      isDisabled={disableMediaProperties || blacklistAlert.status}
                      isLoading={podcastGenresOptionsLoading}
                      value={podcastGenres}
                      options={podcastGenresOptions}
                      onChange={(value) => {
                        setPodcastGenres(value);
                        setPodcastProperty('genre_ids', value?.length > 0 ? value.map(genre => genre.value) : []);
                      }}
                      placeholder="Select Genres..."
                      style={{ maxHeight: '120px' }}
                      styles={colourStyles}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                  <label htmlFor="podcast_url">Podcast URL</label>
                  <Input
                    type="text"
                    id="podcast_url"
                    name="podcast_url"
                    placeholder=""
                    autoComplete="off"
                    onChange={e => setPodcastProperty('url', WVFormatter.normalizeUrl(e.target.value))}
                    value={podcastData.url || ''}
                    disabled={disableMediaProperties || blacklistAlert.status}
                  />
                  {/* errors.podcast_url && (<span className="text-danger">{errors.podcast_url}</span>) */}
                </FormGroup>
              </Col>
              <Col className="mt-2 text-center" md={12}>
                <Button
                  className="modal_ok btn-sm mb-0"
                  color="primary"
                  onClick={saveProspect}
                  disabled={loading || disableMediaProperties || blacklistAlert.status}
                >
                  {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                  <span>Save</span>
                </Button>{' '}
                <Button
                  className="modal_ok btn-sm mb-0"
                  color="danger"
                  onClick={() => {
                    setMediaChannelsData({});
                    setPodcastData({});
                    setProspectData({});
                    setBrand({ value: null, label: '-- Not Assigned --' });
                    setProspectOwner({ value: null, label: '-- Not Assigned --' });
                    setSubmittedBy({
                      value: currentAdminUser ? currentAdminUser._id : null,
                      label: currentAdminUser ? `${currentAdminUser.firstName} ${currentAdminUser.lastName}` : '-- Not Assigned --',
                    });
                    setMediaUrl('');
                  }}
                >
                  <span>Cancel</span>
                </Button>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <InternalRequestModal
        setModalVisible={setModal}
        results={saveResults}
        modal={modal}
        creatorName={`${prospectData.firstname} ${prospectData.lastname}`}
        prospectOwner={prospectOwner?.value}
      />
    </Container>
  );
};

AddInternalProspectRequest.propTypes = {
  // addNotice: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  adminUsers: state.ResearchReducer.adminUsers,
  currentAdminUser: state.session,
});

export default connect(mapStateToProps)(AddInternalProspectRequest);
